import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatPartialDate, sortPartialDate } from 'neuro-utils';

// fields to be shown in dash
const dashFields = ['physicalExercise', 'mealsPerDay', 'smoking'];

// fields to be shown in history view
const historyFields = [
  'physicalExercise',
  'mealsPerDay',
  'hasSpecialDiets',
  'specialDiets',
  'meatEatenLastTime',
  'doesVegetarianDietIncludeEggAndMilk',
  'whichFoodAllergies',
  'whichOtherDiet',
  'coffeeDrinking',
  'howMuchCoffee',
  'coffeeDrinkenLastTime',
  'teaDrinking',
  'howMuchTea',
  'teaDrinkenLastTime',
  'energyDrinkDrinking',
  'howMuchEnergyDrinks',
  'energyDrinksDrinkenLastTime',
  'smoking',
  'smokingStarted',
  'cigarettesPerDay',
  'smokedLastTime',
  'snus',
  'usingSnusStarted',
  'snusPortionsPerDay',
  'snusUsedLastTime',
  'nicotineProducts',
  'usingNicotineProductsStarted',
  'howMuchNicotineProductsUsed',
  'nicotineProductsUsedLastTime',
  'alcoholDrinking',
  'alcoholDrinkingMoreThan6portions',
  'otherIntoxicants',
  'cannabis',
  'otherIntoxicantThanCannabis',
  'whichOtherIntoxicant',
  'otherIntoxicantUse',
  'senseOfSmellWeakened',
  'sinceWhenHasSenseOfSmellWeakened',
  'livingAtCountryside',
  'sinceWhenHasLivedOnCountryside',
  'forHowManyYearsHasLivedOnCountryside',
  'hasDrinkenMainlyWellWater',
  'exposedToSolvents',
  'accomplishedDreams',
];

// fields with partial date value
const partialdates = [
  'meatEatenLastTime',
  'coffeeDrinkenLastTime',
  'teaDrinkenLastTime',
  'energyDrinksDrinkenLastTime',
  'smokingStarted',
  'smokedLastTime',
  'usingSnusStarted',
  'snusUsedLastTime',
  'usingNicotineProductsStarted',
  'nicotineProductsUsedLastTime',
  'sinceWhenHasSenseOfSmellWeakened',
  'sinceWhenHasLivedOnCountryside',
];

// fields with number value
const numbers = ['cigarettesPerDay', 'snusPortionsPerDay', 'forHowManyYearsHasLivedOnCountryside'];

// fields with string value
const strings = ['whichFoodAllergies', 'whichOtherDiet', 'howMuchNicotineProductsUsed', 'whichOtherIntoxicant'];

type TValueObject = { name: string; value: string[] | string };

const hidingRules = [
  {
    name: 'meatEatenLastTime',
    rule: (obj: TValueObject) => obj.name === 'specialDiets' && !obj.value.includes('vegetarian'),
  },
  {
    name: 'doesVegetarianDietIncludeEggAndMilk',
    rule: (obj: TValueObject) => obj.name === 'specialDiets' && !obj.value.includes('vegetarian'),
  },
  {
    name: 'whichFoodAllergies',
    rule: (obj: TValueObject) => obj.name === 'specialDiets' && !obj.value.includes('foodAllergy'),
  },
  {
    name: 'whichOtherDiet',
    rule: (obj: TValueObject) => obj.name === 'specialDiets' && !obj.value.includes('other'),
  },
  {
    name: 'howMuchCoffee',
    rule: (obj: TValueObject) => obj.name === 'coffeeDrinking' && obj.value != 'drinks' && obj.value != 'drankEarlier',
  },
  {
    name: 'coffeeDrinkenLastTime',
    rule: (obj: TValueObject) => obj.name === 'coffeeDrinking' && obj.value != 'drankEarlier',
  },
  {
    name: 'howMuchTea',
    rule: (obj: TValueObject) => obj.name === 'teaDrinking' && obj.value != 'drinks' && obj.value != 'drankEarlier',
  },
  {
    name: 'teaDrinkenLastTime',
    rule: (obj: TValueObject) => obj.name === 'teaDrinking' && obj.value != 'drankEarlier',
  },
  {
    name: 'howMuchEnergyDrinks',
    rule: (obj: TValueObject) =>
      obj.name === 'energyDrinkDrinking' && obj.value != 'drinks' && obj.value != 'drankEarlier',
  },
  {
    name: 'energyDrinksDrinkenLastTime',
    rule: (obj: TValueObject) => obj.name === 'energyDrinkDrinking' && obj.value != 'drankEarlier',
  },
  {
    name: 'smokingStarted',
    rule: (obj: TValueObject) => obj.name === 'smoking' && obj.value != 'smokes' && obj.value != 'smokedEarlier',
  },
  {
    name: 'cigarettesPerDay',
    rule: (obj: TValueObject) => obj.name === 'smoking' && obj.value != 'smokes' && obj.value != 'smokedEarlier',
  },
  {
    name: 'smokedLastTime',
    rule: (obj: TValueObject) => obj.name === 'smoking' && obj.value != 'smokedEarlier',
  },
  {
    name: 'usingSnusStarted',
    rule: (obj: TValueObject) => obj.name === 'snus' && obj.value != 'uses' && obj.value != 'usedEarlier',
  },
  {
    name: 'snusPortionsPerDay',
    rule: (obj: TValueObject) => obj.name === 'snus' && obj.value != 'uses' && obj.value != 'usedEarlier',
  },
  {
    name: 'snusUsedLastTime',
    rule: (obj: TValueObject) => obj.name === 'snus' && obj.value != 'usedEarlier',
  },
  {
    name: 'usingNicotineProductsStarted',
    rule: (obj: TValueObject) => obj.name === 'nicotineProducts' && obj.value != 'uses' && obj.value != 'usedEarlier',
  },
  {
    name: 'howMuchNicotineProductsUsed',
    rule: (obj: TValueObject) => obj.name === 'nicotineProducts' && obj.value != 'uses' && obj.value != 'usedEarlier',
  },
  {
    name: 'nicotineProductsUsedLastTime',
    rule: (obj: TValueObject) => obj.name === 'nicotineProducts' && obj.value != 'usedEarlier',
  },
  {
    name: 'alcoholDrinkingMoreThan6portions',
    rule: (obj: TValueObject) => obj.name === 'alcoholDrinking' && obj.value === 'never',
  },
  {
    name: 'cannabis',
    rule: (obj: TValueObject) => obj.name === 'otherIntoxicants' && obj.value != 'yes',
  },
  {
    name: 'otherIntoxicantThanCannabis',
    rule: (obj: TValueObject) => obj.name === 'cannabis' && obj.value === 'never',
  },
  {
    name: 'whichOtherIntoxicant',
    rule: (obj: TValueObject) => obj.name === 'otherIntoxicants' && obj.value != 'yes',
  },
  {
    name: 'otherIntoxicantUse',
    rule: (obj: TValueObject) => obj.name === 'otherIntoxicants' && obj.value != 'yes',
  },
  {
    name: 'sinceWhenHasSenseOfSmellWeakened',
    rule: (obj: TValueObject) => obj.name === 'senseOfSmellWeakened' && obj.value != 'yes',
  },
  {
    name: 'sinceWhenHasLivedOnCountryside',
    rule: (obj: TValueObject) => obj.name === 'livingAtCountrySide' && obj.value != 'yes',
  },
  {
    name: 'forHowManyYearsHasLivedOnCountryside',
    rule: (obj: TValueObject) => obj.name === 'livingAtCountryside' && obj.value != 'yes',
  },
  {
    name: 'hasDrinkedMainlyWellWater',
    rule: (obj: TValueObject) => obj.name === 'livingAtCountryside' && obj.value != 'yes',
  },
  {
    name: 'specialDiets',
    rule: (obj: TValueObject) => obj.name === 'hasSpecialDiets' && obj.value !== 'yes',
  },
];

// checks document and determines if there are fields that should be hidden
export const hide = (arr: IFieldItem[], item: IFieldItem): boolean => {
  if (item === undefined || arr.length === 0) {
    return false;
  }

  const rule = hidingRules.find((r) => r.name === item.name);
  if (rule && arr.find(rule.rule)) return true;

  return false;
};

/**
 * Function for merging data from multiple arrays into one document so that every field has the most present information
 * @param d - Array of documents
 * return an array containing objects with information about field name, value, date (also if it needs to be hidden)
 *
 */
export const mergeDocuments = (
  documentFields: 'dash' | 'history',
  documents: { [key: string]: any }[],
): Array<IFieldItem> => {
  let fields: string[] = [];
  if (documentFields === 'dash') {
    fields = dashFields;
  } else {
    fields = historyFields;
  }
  documents.sort((n1: any, n2: any) => sortPartialDate(n1.date, n2.date)).reverse();
  const arr: Array<IFieldItem> = [];
  for (let i = 0; i < fields.length; i++) {
    for (let j = 0; j < documents.length; j++) {
      if (documents[j][fields[i]]) {
        if (documentFields === 'history') {
          arr.push({
            id: documents[j]._id,
            name: fields[i],
            value: documents[j][fields[i]],
            date: documents[j].date,
            hide: false,
          });
        } else {
          arr.push({
            id: documents[j]._id,
            name: fields[i],
            value: documents[j][fields[i]],
            date: documents[j].date,
          });
        }
        break;
      } else {
        continue;
      }
    }
  }
  if (documentFields === 'history') {
    for (let i = 0; i < arr.length; i++) {
      arr[i].hide = hide(arr, arr[i]);
    }
  }
  return arr;
};

// Function for turning the value into formatted message, list or whatever needed
export const parseValue = (item: IFieldItem): React.JSX.Element | number | string | undefined => {
  if (!item.value || !item.name) {
    return '-';
  }
  if (item.name === 'specialDiets') {
    if (item.value.length === 0) {
      return '-';
    } else if (item.value.length === 1) {
      return <FormattedMessage id={`lifestyle.specialDiets.${item.value[0]}`} />;
    } else {
      return (
        <>
          {item.value.map((i: any) => (
            <div key={i}>
              <FormattedMessage id={`lifestyle.specialDiets.${i}`} />
            </div>
          ))}
        </>
      );
    }
  } else if (partialdates.indexOf(item.name) != -1) {
    return formatPartialDate(item.value);
  } else if (numbers.indexOf(item.name) != -1 || strings.indexOf(item.name) != -1) {
    return item.value;
  } else {
    return <FormattedMessage id={`lifestyle.${item.name}.${item.value}`} />;
  }
};

export interface IFieldItem {
  id?: string;
  name: string;
  value: any;
  date: PartialDate | undefined;
  hide?: boolean;
}
