import * as React from 'react';
import { styled } from '@mui/system';
import { MyServiceContext } from '../../..';
import SymptomWindow from './SymptomWindow';
import colors from '../../../../../../config/theme/colors';
import { Container, Item } from 'Components/Grid';
import { AddCircle } from '@mui/icons-material';
import SleepWindow from './SleepWindow';
import BlockWrapper from 'Components/_NewElements/BlockWrapper';
import FormRow from 'Components/FormRow';
import InputHandler from 'Components/InputHandler';
import { isPartialDate } from 'neuro-utils';
import MedicationWindow from '../components/MedicationWindow';
import { medicationFields } from './config';

const StyledHeader = styled('div')({
  color: `${colors.primary}`,
  fontSize: '2rem',
  fontWeight: 600,
});

const StyledHeaderWithControls = styled('div')({
  display: 'grid',
  gridTemplateColumns: '5fr 2fr',
  alignItems: 'center',
});

const StyledAddButton = styled('div', {
  shouldForwardProp: (prop) => prop !== 'disabled',
})(({ disabled }: { disabled?: boolean }) => ({
  display: 'flex',
  alignItems: 'center',
  fontWeight: 600,
  color: `${disabled ? colors.gray : colors.primary}`,
  userSelect: 'none' as const,
  '&:hover': {
    cursor: disabled ? 'default' : 'pointer',
  },
}));

const AddButton = ({
  fm,
  disabled,
  text = 'myService.ninmt.addSymptom',
}: {
  fm: (m: string, n?: string | number | { [key: string]: React.JSX.Element } | undefined) => string;
  disabled?: boolean;
  text?: string;
}): React.JSX.Element => {
  return (
    <StyledAddButton disabled={disabled}>
      <AddCircle />
      <span style={{ marginLeft: '0.7rem' }}>{fm(text)}</span>
    </StyledAddButton>
  );
};

const NINMTPreInquiry = (): React.JSX.Element => {
  const myServiceContext = React.useContext(MyServiceContext);
  const { editing, fm, viewing, setEditingData } = myServiceContext;

  const ninmtPreInquiryData = ((editing?.data || viewing?.data) ?? {}) as ININMTPreInquiry & IControlProps;

  const surveyData = {
    ...ninmtPreInquiryData,
    primarySymptom: ninmtPreInquiryData.primarySymptom ?? {},
    sleep: ninmtPreInquiryData.sleep ?? {},
    medication: ninmtPreInquiryData.medication ?? [],
  };

  // Used to control the "editing" dialog in MedicationWindow
  const [medicationDialogOpen, setMedicationDialogOpen] = React.useState<boolean>(false);

  return (
    <React.Fragment>
      <FormRow title={'general.date'} headerWidth={!editing ? 1 : 3.9}>
        <InputHandler
          name="date"
          type="PartialDate"
          editing={!!editing}
          isNotCancellable={true}
          formData={{
            document: { date: surveyData?.date },
            onChange: (e: TOnChangeValues) => {
              const value = e.date;
              if (value && isPartialDate(value)) {
                setEditingData?.({ ...surveyData, date: value });
              }
            },
          }}
          dateDefault="now"
        />
      </FormRow>
      <BlockWrapper>
        {/**
         * Symptoms
         */}
        <Container style={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: '1.5rem' }}>
          <Item xs={10}>
            <StyledHeader>{fm('myService.ninmt.primarySymptom')}</StyledHeader>
          </Item>
          <Item xs={2} />
        </Container>
        <SymptomWindow parentDocument={surveyData} document={surveyData.primarySymptom} />
        {/**
         * Sleep
         */}
        <Container style={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Item xs={10}>
            <StyledHeader>{fm('myService.ninmt.sleep')}</StyledHeader>
          </Item>
          <Item xs={2} />
        </Container>
        <SleepWindow parentDocument={surveyData} document={surveyData.sleep} />
        {/**
         * Medication
         */}
        <div style={{ width: '70%' }}>
          <StyledHeaderWithControls
            style={{
              marginBottom: surveyData.medication.length > 0 ? undefined : '3.5rem',
            }}
          >
            <StyledHeader>{fm('myService.ninmt.medication')}</StyledHeader>
            <div style={{ display: viewing ? 'none' : 'flex', justifyContent: 'flex-end', paddingRight: '1rem' }}>
              <div onClick={() => setMedicationDialogOpen(true)}>
                <AddButton fm={fm} disabled={false} text="myService.ninmt.addMedication" />
              </div>
            </div>
          </StyledHeaderWithControls>
        </div>

        <MedicationWindow
          parentDocument={surveyData}
          document={surveyData.medication}
          dialogOpen={medicationDialogOpen}
          setDialogOpen={setMedicationDialogOpen}
          fields={medicationFields}
          formType={'ninmtPreInquiry'}
        />
      </BlockWrapper>
    </React.Fragment>
  );
};

export default NINMTPreInquiry;
