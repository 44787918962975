import { exists, formatPartialDate, isPartialDate, nowPartialDate, sortPartialDate } from 'neuro-utils';
import { subjectOfTreatmentNames } from 'Routes/DoctorsOrders/Document/config';
import { v4 } from 'uuid';
import { omit, path } from './ramdaReplacement';

export type TRTMSEvent = IRTMSSession | IRTMSUnusedSession | IRTMSComplication | IRTMSAdverseEffect;
export type TTDCSEvent = ITDCSSession | ITDCSComplication | ITDCSAdverseEffect | ITDCSOtherEvent;

type TTreatment = IRTMSTreatment | ITDCSTreatment;
type TSession = IRTMSSession | ITDCSSession;

export const iTBSFields = [
  'session',
  'stimulationType',
  'peelingDepth',
  'pulseFrequency3PulseBurst',
  'frequencyBetweenBursts',
  'amountOfBurstsInSet',
  'amountOfSetsInSequence',
  'timeBetweenBurstsets',
  'pulseIntensity',
  'numberOfPulses',
  'pauseLength',
  'electricFieldStrength',
  'additionalInformation',
];

export const cTBSFields = iTBSFields.filter((f) => f !== 'timeBetweenBurstsets');

export const originalRTMSFields = cTBSFields
  .map((f) => (f === 'pulseFrequency3PulseBurst' ? 'pulseFrequency' : f))
  .filter((f) => !['frequencyBetweenBursts', 'amountOfBurstsInSet', 'amountOfSetsInSequence'].includes(f));

export const tdcsFields = [
  'session',
  'type',
  'cathode',
  'anode',
  'current',
  'currentDuration',
  'additionalInformation',
];

/**
 * Get the session number of a treatment, which tells how many times a treatment target has been treated
 * @param {Array<TSession>} sessions - RTMS/TDCS sessions
 * @param {number} currentIndex - Index of current/selected session
 * @param {TTreatment} subjectOfTreatment - Current/selected treatment target
 * @param {true} reverse - Set to true if the array is to be inspected in reverse (oldest --> newest)
 * @returns {number} - Session number of the treatment (which is 0 if there are no sessions for given treatment target)
 */
export const getSessionNumber = (
  sessions: Array<TSession>,
  currentIndex: number,
  subjectOfTreatment: TTreatment,
  reverse?: true,
): number =>
  sessions.slice(reverse ? 0 : currentIndex + 1, reverse ? currentIndex + 1 : undefined).filter(
    (s) =>
      s.subjectOfTreatment?.some((sot) => {
        if (!sot.deleted && sot.name === subjectOfTreatment.name && sot.specifier == subjectOfTreatment.specifier) {
          if (sot.name !== 'other') return true;
          if (sot.nameOther === subjectOfTreatment.nameOther) return true;
        }
        return false;
      }),
  ).length;

export type TSOTNameObj = { name: string; nameOther?: string; specifier?: string };

/**
 * Create an object containing 'name' and possible 'nameOther' and 'specifier' of given subject of treatment
 * @param {IRTMSTreatment | ITDCSTreatment} s - Subject of treatment
 * @returns {TSOTNameObj} - An object containing 'name' and possible 'nameOther' and 'specifier' of given subject of treatment
 */
export const createSubjectOfTreatmentNameObj = (s: IRTMSTreatment | ITDCSTreatment): TSOTNameObj => {
  const sot: TSOTNameObj = { name: s.name };
  if (s.name === 'other' && s.nameOther) sot.nameOther = s.nameOther;
  if (s.specifier) sot.specifier = s.specifier;
  return sot;
};

/**
 * Title of subject of treatment combined with specifier
 * @param {TTreatment} s - Subject of treatment
 * @param {'rtms' | 'tdcs'} t - Treatment type, 'rtms' or 'tdcs'
 * @param {(id: string) => string} fm - FormatMessage function
 * @returns {string} - Title, e.g. "TOP kek (jalka) 1"
 */
export const subjectOfTreatmentTitle = (s: TTreatment, t: 'rtms' | 'tdcs', fm: (id: string) => string): string =>
  `${
    s && typeof s.name === 'string' && s.name.length > 0
      ? s.name === 'other'
        ? s.nameOther
        : subjectOfTreatmentNames.includes(s.name)
          ? fm(`${t}.subjectOfTreatmentNames.${s.name}`)
          : s.name
      : fm(`${t}.subjectOfTreatment.treatmentTitlePlaceholder`)
  }${s && s.specifier ? ' ' + s.specifier : ''}`;

/**
 * Origin and date of the reference document from where the values were copied to given subject of treatment
 * @param {TTreatment} s - Subject of treatment
 * @param {'rtms' | 'tdcs'} t - Treatment type, 'rtms' or 'tdcs'
 * @param {(id: string) => string} fm - FormatMessage function
 * @returns {string} - Origin and date, e.g. "Lääkärin määräys (1970-01-01)"
 */
export const originAndDateOfReference = (s: TTreatment, t: 'rtms' | 'tdcs', fm: (id: string) => string): string => {
  let origin = path(['reference', 'origin'], s) ?? '-';
  let date = path(['reference', 'date'], s) ?? '-';
  if (origin === 'doctorsOrder' || origin === 'session') origin = fm(`rtms.${origin}`);
  if (isPartialDate(date)) date = formatPartialDate(date);
  return `${origin} (${date})`;
};

/**
 * Header text (date - endDate) to be used in RTMS/TDCS history view and dash tile
 * @param {TRTMSEvent | TTDCSEvent} d - RTMS/TDCS document
 * @param {((id: string) => string) | undefined} fm - FormatMessage function to format treatment type if it is to be included
 * @returns {string} - Header text which is either date, date + endDate, date + type or date + endDate + type
 */
export const headerText = (d: ITDCS, fm?: (id: string) => string): string =>
  `${d.date ? formatPartialDate(d.date) : ''}${
    d?.hasEnded
      ? isPartialDate(d.endDate)
        ? ` - ${formatPartialDate(d.endDate)}`
        : Array.isArray(d.sessions) && d.sessions.length > 0
          ? ` - ${formatPartialDate(d.sessions[0].date)}`
          : ''
      : ''
  }${fm && d.type ? `\u00A0\u00A0${fm(`rtms.opts.${d.type}`)}` : ''}`;

/**
 * Determines the treatment period timeframe that includes the given session.
 * @param {TSession | undefined} currentSession - The session for which to find the treatment period timeframe
 * @param {Array<ININMTTreatmentPeriod> | undefined} ninmtTreatmentPeriods - List of treatment periods to check against
 * @returns {Array<PartialDate | undefined> | undefined} - If found, the timeframe ([startDate, endDate]) of the matching treatment period, else undefined
 */
export const findCurrentTreatmentPeriodTimeframe = (
  currentSession?: TSession,
  ninmtTreatmentPeriods?: Array<ININMTTreatmentPeriod>,
): Array<PartialDate | undefined> | undefined => {
  if (!currentSession || !Array.isArray(ninmtTreatmentPeriods)) return undefined;

  let currentTimeframe: (PartialDate | undefined)[] | undefined;
  if (ninmtTreatmentPeriods) {
    const timeframes = ninmtTreatmentPeriods
      .filter((d) => isPartialDate(d.date))
      .sort((a, b) => a._cdate - b._cdate)
      .sort((a, b) => sortPartialDate(a.date, b.date))
      .map((p, i, arr) => [p.date, arr[i + 1]?.date]);

    currentTimeframe = timeframes.find(
      ([start, end]) =>
        sortPartialDate(currentSession.date, start) >= 0 && (!end || sortPartialDate(currentSession.date, end) <= 0),
    );
  }
  return currentTimeframe;
};

/**
 * Find doctor's order preceding current RTMS/TDCS session
 * @param {'rtms' | 'tdcs'} treatmentType - Treatment type
 * @param {Array<IDoctorsOrder>} doctorsOrders - Array of doctor's orders documents
 * @param {Array<Session>} sessionsSorted - Sorted RTMS/TDCS sessions (new --> old)
 * @param {number} currentIndex - Index of current/selected session
 * @param {Array<string> | undefined} requiredFields - Required fields in given doctor's order, defaults to ['subjectOfTreatment']
 * @param {Array<ININMTTreatmentPeriod> | undefined} ninmtTreatmentPeriods - Filter doctorsOrders to only include those that match the treatment period of current/selected session
 * @returns {IDoctorsOrder | undefined} - Preceding doctor's order or undefined if not found
 */
export const findPrecedingDoctorsOrder = (
  treatmentType: 'rtms' | 'tdcs',
  doctorsOrders: Array<IDoctorsOrder>,
  sessionsSorted: Array<TSession>,
  currentIndex: number,
  requiredFields: Array<string> = ['subjectOfTreatment'],
  ninmtTreatmentPeriods?: Array<ININMTTreatmentPeriod>,
): IDoctorsOrder | undefined => {
  if (!doctorsOrders.length || !sessionsSorted[currentIndex]) return undefined;

  const currentSession = sessionsSorted[currentIndex];
  if (!doctorsOrders.some((d) => d[treatmentType])) return undefined;

  const currentTimeframe = findCurrentTreatmentPeriodTimeframe(currentSession, ninmtTreatmentPeriods);

  const isWithinTimeframe = (order: IDoctorsOrder) => {
    if (!currentTimeframe) return true;
    const [start, end] = currentTimeframe;
    return sortPartialDate(order.date, start) >= 0 && (!end || sortPartialDate(order.date, end) <= 0);
  };

  const isValidOrder = (order: IDoctorsOrder) => {
    const orderData = order[treatmentType];
    if (!orderData) return false;
    if ((orderData.createDate ?? 0) > (currentSession.createDate ?? 0)) return false;
    if (sortPartialDate(order.date, currentSession.date) > 0) return false;

    if (requiredFields.includes('subjectOfTreatment')) {
      if (
        orderData.doesNotApplyToSubjectOfTreatment ||
        !Array.isArray(orderData.subjectOfTreatment) ||
        orderData.subjectOfTreatment.length === 0
      ) {
        return false;
      }
    }

    if (
      treatmentType === 'rtms' &&
      requiredFields.some((f) => ['device', 'rmt'].includes(f)) &&
      (orderData as Omit<IRTMSSession, 'id' | 'date'>).doesNotApplyToRMT
    ) {
      return false;
    }

    return requiredFields.every((field) => exists(orderData[field as keyof typeof orderData]));
  };

  return doctorsOrders
    .filter((order) => order[treatmentType] && isWithinTimeframe(order))
    .sort(
      (a, b) =>
        sortPartialDate(b.date, a.date) || (b[treatmentType]?.createDate ?? 0) - (a[treatmentType]?.createDate ?? 0),
    )
    .find(isValidOrder);
};

type TAddingSessionDisabledReason =
  | 'noDoctorsOrder'
  | 'rtmsContraIndication'
  | 'rtmsContraIndicationsNotConfirmed'
  | 'tdcsContraIndication'
  | 'tdcsContraIndicationsNotConfirmed';

/**
 * Determine if there is a reason why adding a new RTMS/TDCS treatment session is disabled
 * @param {'rtms' | 'tdcs'} treatmentType - Treatment type
 * @param {Array<IDoctorsOrder>} doctorsOrders - Array of doctor's orders documents
 * @param {Array<IContraIndicationToTreatment>} contraIndications - Array of contraindication documents
 * @param {IFormData} formData - Current formData
 * @param {string | null} userId - Current user id
 * @returns {TAddingSessionDisabledReason | undefined} - Reason why adding a new RTMS/TDCS session is disabled, undefined if there is none
 */
export const addingTreatmentSessionDisabledReason = (
  treatmentType: 'rtms' | 'tdcs',
  doctorsOrders: Array<IDoctorsOrder>,
  contraIndications: Array<IContraIndicationToTreatment>,
  formData: IFormData,
): TAddingSessionDisabledReason | undefined => {
  const reqFields = Array.isArray(formData.document.sessions) && formData.document.sessions.length > 0 ? [] : undefined;

  const precedingDoctorsOrder = findPrecedingDoctorsOrder(
    treatmentType,
    doctorsOrders,
    [{ id: '', createDate: Date.now(), date: nowPartialDate() }],
    0,
    reqFields,
  );

  // Check if...

  // ...there is no preceding doctor's order (with subjects of treatment, if adding first session)
  if (!precedingDoctorsOrder) return 'noDoctorsOrder';

  const latestContraIndication = contraIndications.sort((a, b) => sortPartialDate(b.date, a.date))[0];

  // RTMS session adding disabled
  if (treatmentType === 'rtms') {
    const rtmsContraIndication =
      latestContraIndication &&
      ((latestContraIndication.tmsAbsoluteContraIndications &&
        latestContraIndication.tmsAbsoluteContraIndications.length > 0) ||
        (latestContraIndication.tmsRelativeContraIndications &&
          latestContraIndication.tmsRelativeContraIndications.length > 0));

    if (!latestContraIndication || (!latestContraIndication.noRtmsContraIndications && !rtmsContraIndication)) {
      return 'rtmsContraIndicationsNotConfirmed';
    }
    if (rtmsContraIndication && !latestContraIndication.rtmsRegardlessOfContraIndications) {
      return 'rtmsContraIndication';
    }
  }

  // TDCS session adding disabled
  if (treatmentType === 'tdcs') {
    const tdcsContraIndication =
      latestContraIndication &&
      latestContraIndication.dcsAbsoluteContraIndications &&
      latestContraIndication.dcsAbsoluteContraIndications.length > 0;

    if (!latestContraIndication || (!latestContraIndication.noTdcsContraIndications && !tdcsContraIndication)) {
      return 'tdcsContraIndicationsNotConfirmed';
    }
    if (tdcsContraIndication && !latestContraIndication.tdcsRegardlessOfContraIndications) {
      return 'tdcsContraIndication';
    }
  }

  return undefined;
};

/**
 * For copying values from a preceding doctor's order or previous session, create a copy of the values and save them in the subject of treatment for reference
 * @param {'rtms' | 'tdcs'} treatmentType - Treatment type
 * @param {IDoctorsOrder} precedingDoctorsOrder - Preceding doctor's order document
 * @param {Array<TSession>} sessionsSorted - Sorted RTMS/TDCS sessions (new --> old)
 * @param {number} currentIndex - Index of current/selected session
 * @returns {Record<string, unknown> | undefined} - Subjects of treatment with values copied to corresponding fields and saved for reference
 */
export const createReferenceDocument = (
  treatmentType: 'rtms' | 'tdcs',
  precedingDoctorsOrder: IDoctorsOrder | undefined,
  sessionsSorted: Array<TSession>,
  currentIndex: number,
): Record<string, unknown> | undefined => {
  const p = precedingDoctorsOrder as { [key: string]: any };
  // Case where preceding doctor's order doesn't exist isn't allowed
  if (!p || !Array.isArray(p[treatmentType]?.subjectOfTreatment)) return undefined;

  // A session exists after preceding doctor's order
  const previousSession =
    currentIndex !== (sessionsSorted?.length ?? 0) - 1 &&
    // A session exists after preceding doctor's order
    (sessionsSorted?.[currentIndex + 1]
      ? sortPartialDate(sessionsSorted?.[currentIndex + 1].date, p.date) >= 0 &&
        (sortPartialDate(sessionsSorted?.[currentIndex + 1].date, p.date) > 0 ||
          (sortPartialDate(sessionsSorted?.[currentIndex + 1].date, p.date) === 0 &&
            (sessionsSorted?.[currentIndex + 1]?.createDate ?? 0) - (p[treatmentType]?.createDate ?? 0) > 0))
      : true)
      ? sessionsSorted?.[currentIndex + 1]
      : undefined;

  // If only preceding doctor's order exists
  if (!previousSession || !Array.isArray(previousSession.subjectOfTreatment)) {
    return {
      subjectOfTreatment: p[treatmentType]?.subjectOfTreatment?.map((s: TTreatment) => ({
        ...s,
        reference: { origin: 'doctorsOrder', date: p.date, values: omit(['reference'], s) },
      })),
    };
  }

  // If both preceding doctor's order and previous session exist
  if (previousSession && Array.isArray(previousSession.subjectOfTreatment)) {
    // Preceding doctor's order
    const pd = p;
    // Previous sessions
    const pss = sessionsSorted.filter((s) => s.id !== sessionsSorted?.[currentIndex].id);

    // If subjects of treatment have been deleted in previous session(s), map subjects of treatments from preceding doctor's orders to places they're missing from
    const subjectsOfTreatmentsCombined = pd[treatmentType]?.subjectOfTreatment?.map((s: TTreatment) => {
      // Subject of treatment
      let psTr: TTreatment | undefined = undefined;

      // Previous session with subject of treatment
      const ps = pss.find((p) => {
        psTr = p.subjectOfTreatment?.find(
          (pps) => !pps.deleted && (pps.name === s.name || pps.name === s.nameOther) && pps.specifier === s.specifier,
        );
        if (psTr) return true;
        return false;
      });

      if (typeof psTr == 'object') {
        return {
          ...(psTr as TTreatment),
          reference: {
            origin: 'session',
            date: ps?.date,
            values: omit(['reference'], psTr),
            // In case where origin is session, save preceding doctor's order values for comparison
            doctorsOrderValues: omit(['reference'], s),
          },
        };
      }
      // If any previous session couldn't provide a reference value, refer to doctor's orders
      return {
        ...s,
        reference: { origin: 'doctorsOrder', date: pd.date, values: omit(['reference'], s) },
      };
    });

    return {
      subjectOfTreatment: subjectsOfTreatmentsCombined,
    };
  }

  return undefined;
};

type TValueDiffersFromDocOrdersParamsFinalBOSS = [
  currentSession: TSession | undefined,
  currentIndex: number,
  field: string,
  value: number | string | null | undefined,
  valueIndex?: number,
];

/**
 * The value in treatment target is compared to the value in doctor's order treatment target with the same index
 * @param {TSession} currentSession - Current treatment session where the checks are done in
 * @param {number} currentIndex - Index of selected/active treatment target
 * @param {string} field - Field to be compared
 * @param {number | null | undefined} value - Value to be compared
 * @param {number} valueIndex - In case value is an array, array index of the value
 * @param {boolean} getValue - If the value of the compared field is wanted
 * @returns {boolean | number | string | null | undefined | Array<number | string | null | undefined>} - if getValue not given: True if value differs from preceding doctor's orders, false if not. Else: value of the compared field
 */
export function valueDiffersFromDoctorsOrders(...params: TValueDiffersFromDocOrdersParamsFinalBOSS): boolean;
export function valueDiffersFromDoctorsOrders(
  ...params: [...TValueDiffersFromDocOrdersParamsFinalBOSS, ...[getValue?: boolean]]
): number | string | null | undefined | Array<number | string | null | undefined>;
export function valueDiffersFromDoctorsOrders(
  ...params: [...TValueDiffersFromDocOrdersParamsFinalBOSS, ...[getValue?: boolean]]
): boolean | number | string | null | undefined | Array<number | string | null | undefined> {
  const currentSession = params[0];
  const currentIndex = params[1];
  const field = params[2];
  const value = params[3];
  const valueIndex = params[4];
  const getValue = params[5];

  let subjectsOfTreatments: Array<TTreatment> | undefined = undefined;

  if (currentSession && Array.isArray(currentSession.subjectOfTreatment)) {
    subjectsOfTreatments = currentSession.subjectOfTreatment ?? [];
  }

  if (!Array.isArray(subjectsOfTreatments) || !subjectsOfTreatments[currentIndex ?? 0]) return false;

  const referenceValues =
    subjectsOfTreatments[currentIndex].reference?.origin === 'doctorsOrder'
      ? subjectsOfTreatments[currentIndex].reference?.values
      : subjectsOfTreatments[currentIndex].reference?.doctorsOrderValues;

  let comparisonValue = referenceValues?.[field as keyof (IRTMSTreatmentEvent | ITDCSTreatmentEvent)] as
    | typeof value
    | Array<typeof value>;

  switch (field) {
    // Pulse intensity only requires value to be within given range instead of exact match
    case 'pulseIntensity':
      if (!exists(valueIndex) || !Array.isArray(comparisonValue)) {
        // Expected type of comparisonValue: undefined
        if (comparisonValue ? comparisonValue !== value : !!comparisonValue !== !!value)
          return getValue ? comparisonValue : true;
      } else {
        // Expected type of comparisonValue: [number | null, number | null]
        const pulseIntensity = (subjectsOfTreatments[currentIndex] as IRTMSTreatment)?.pulseIntensity;
        if (Array.isArray(pulseIntensity)) {
          // Expected order: ascending (index 0 is min, index 1 is max)
          if (
            (!comparisonValue[0] || (pulseIntensity[valueIndex ?? 0] ?? comparisonValue[0]) >= comparisonValue[0]) &&
            (!comparisonValue[1] || (pulseIntensity[valueIndex ?? 0] ?? comparisonValue[1]) <= comparisonValue[1])
          )
            return false;
        }
        return getValue ? comparisonValue[valueIndex ?? 0] : true;
      }
      return false;
    default:
      if (exists(valueIndex) && Array.isArray(comparisonValue))
        comparisonValue = (comparisonValue as Array<number | null>)[valueIndex ?? 0];

      if (comparisonValue !== value && ![comparisonValue, value].every((v) => !v))
        return getValue ? comparisonValue : true;

      return false;
  }
}

/**
 * Function used to help with displaying only correct/necessary values in RTMS history view table rows
 * @param {keyof IRTMSTreatmentEvent} f - Field name
 * @param {IRTMSTreatment} t - Treatment document
 * @returns {{ name: string; value: any; fieldMatchesType: boolean }} - An object with title (name), value and condition to be used in history view table row
 */
export const getRtmsRowData = (
  f: keyof IRTMSTreatmentEvent,
  t: IRTMSTreatment,
): { name: string; value: any; fieldMatchesType: boolean } => {
  const fields = {
    originalRTMS: originalRTMSFields,
    iTBS: iTBSFields,
    cTBS: cTBSFields,
  }[t.stimulationType ?? 'originalRTMS'];

  return { name: f, value: t[f], fieldMatchesType: !t.deleted && fields.includes(f) };
};

/**
 * Function used to help with displaying only correct/necessary values in TDCS history view table rows
 * @param {keyof ITDCSTreatmentEvent} f - Field name
 * @param {ITDCSTreatment} t - Treatment document
 * @returns {{ name: string; value: any; fieldMatchesType: boolean }} - An object with title (name), value and condition to be used in history view table row
 */
export const getTdcsRowData = (
  f: keyof ITDCSTreatmentEvent,
  t: ITDCSTreatment,
): { name: string; value: any; fieldMatchesType: boolean } => {
  let name = f;
  let value = t[name];
  let fieldMatchesType = false;
  if (
    !['cathode', 'anode'].includes(name) ||
    (t.type === 'anode' && name === 'cathode') ||
    (t.type === 'cathode' && name === 'anode')
  ) {
    fieldMatchesType = true;
    if (value === 'other') {
      name = `${f as 'cathode' | 'anode'}Other`;
      value = t[name];
    }
  }
  return { name: name, value: value, fieldMatchesType: fieldMatchesType };
};

/**
 * Converts symptomsAndLocations to rating format
 * @param {IRTMSSession | ITDCSSession} currentSession - Current RTMS/TDCS session
 * @param {Array<ISymptomsAndLocations>} symptomsAndLocations - Array of symptoms and locations in current treatment period
 * @returns {Array<IRTMSRating | ITDCSRating>} - Symptoms and locations converted to rating format, empty array if nothing to convert
 */
export const convertSymptomsAndLocationsToRatingFormat = (
  currentSession: IRTMSSession | ITDCSSession,
  symptomsAndLocations: Array<ISymptomsAndLocations>,
): Array<IRTMSRating | ITDCSRating> => {
  if (typeof currentSession !== 'object' || !isPartialDate(currentSession.date) || !Array.isArray(symptomsAndLocations))
    return [];

  const activeSymptomsAndLocations = symptomsAndLocations.filter(
    (sl) =>
      isPartialDate(sl.startDate) &&
      sortPartialDate(sl.startDate, currentSession.date) <= 0 &&
      (!isPartialDate(sl.endDate) || sortPartialDate(sl.endDate, currentSession.date) > 0) &&
      sl.mentalSymptom !== true,
  );

  const convertedSymptomsAndLocations: Array<IRTMSRating> = [];

  const symptoms: Array<ISymptomsAndLocations['symptom']> = ['pain', 'tinnitus', 'other'].filter((symptom) =>
    activeSymptomsAndLocations.some((sl) => sl.symptom === symptom),
  ) as Array<ISymptomsAndLocations['symptom']>;

  symptoms.forEach((symptom) => {
    const patientsRating: IRTMSRating | ITDCSRating = { id: v4(), symptom: symptom };

    patientsRating.ratingByLocation = activeSymptomsAndLocations
      .filter((sl) => sl.symptom === symptom)
      .map((sl) => {
        const ratingByLocation: TRTMSRatingByLocation | TTDCSRatingByLocation = {
          symptomsAndLocationsId: sl._id,
          intensity: { beforeTreatment: null, afterTreatment: null },
          harm: { beforeTreatment: null, afterTreatment: null },
        };
        if (sl.symptom !== 'other' && sl.symptomLocation) ratingByLocation.location = sl.symptomLocation;
        if (sl.symptom === 'other' && sl.symptomDescription && sl.symptomDescription.length > 0)
          ratingByLocation.description = sl.symptomDescription;
        return ratingByLocation;
      }) as Array<TRTMSRatingByLocation | TTDCSRatingByLocation>;

    convertedSymptomsAndLocations.push(patientsRating);
  });

  return convertedSymptomsAndLocations;
};

/**
 * Converts treatment efficiency to rating format
 * @param {ITreatmentEfficiency | [ITreatmentEfficiency, ITreatmentEfficiency]} treatmentEfficiency - Single or paired treatment efficiency data
 * @returns {Array<IRTMSRating | ITDCSRating>} - Converted treatment efficiency in rating format, empty array if nothing to convert
 */
export const convertTreatmentEfficiencyToRatingFormat = (
  treatmentEfficiency: ITreatmentEfficiency | [ITreatmentEfficiency, ITreatmentEfficiency],
): Array<IRTMSRating | ITDCSRating> => {
  const efficiencies = Array.isArray(treatmentEfficiency) ? treatmentEfficiency : [treatmentEfficiency];

  if (
    efficiencies.length === 0 ||
    efficiencies.some((eff) => !eff || typeof eff !== 'object' || !isPartialDate(eff.date) || !eff.timing)
  ) {
    return [];
  }

  const symptoms: Array<'pain' | 'tinnitus' | 'other'> = ['pain', 'tinnitus', 'other'];
  const convertedEfficiency: Array<IRTMSRating | ITDCSRating> = [];

  symptoms.forEach((symptom) => {
    const beforeData = efficiencies.find((eff) => eff.timing === 'before')?.[symptom] || [];
    const afterData = efficiencies.find((eff) => eff.timing === 'after')?.[symptom] || [];

    if (beforeData.length > 0 || afterData.length > 0) {
      const patientsRating: IRTMSRating | ITDCSRating = { id: v4(), symptom: symptom };

      const uniqueRatings = new Map<string, TRTMSRatingByLocation | TTDCSRatingByLocation>();

      [...beforeData, ...afterData].forEach((rating) => {
        const key = rating
          ? 'location' in rating && rating.location
            ? rating.location
            : 'description' in rating && rating.description
              ? rating.description
              : ''
          : '';

        if (!uniqueRatings.has(key)) {
          uniqueRatings.set(key, {
            intensity: { beforeTreatment: null, afterTreatment: null },
            harm: { beforeTreatment: null, afterTreatment: null },
          });
        }

        const target = uniqueRatings.get(key)!;

        if (beforeData.includes(rating)) {
          target.intensity!.beforeTreatment = (rating.intensity ?? null) as number;
          target.harm!.beforeTreatment = (rating.harm ?? null) as number;
        }

        if (afterData.includes(rating)) {
          target.intensity!.afterTreatment = (rating.intensity ?? null) as number;
          target.harm!.afterTreatment = (rating.harm ?? null) as number;
        }

        if (symptom !== 'other' && 'location' in rating && rating.location) {
          target.location = rating.location;
        } else if (symptom === 'other' && 'description' in rating && rating.description) {
          target.description = rating.description;
        }
      });

      patientsRating.ratingByLocation = Array.from(uniqueRatings.values());
      convertedEfficiency.push(patientsRating);
    }
  });

  return convertedEfficiency;
};
