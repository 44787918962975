import * as React from 'react';
import FormRow from 'Components/FormRow';
import InputHandler from 'Components/InputHandler';
import BlockWrapper from 'Components/_NewElements/BlockWrapper';
import { IPDQ8, pdq8, isLocaleKey, Task_Progress } from 'neuro-schemas';
import { formatPartialDate, arrayOfAll } from 'neuro-utils';
import { MyServiceContext } from '../..';
import colors from '../../../../../config/theme/colors';

const fields = arrayOfAll.keys<Omit<IPDQ8, 'date'>>()([
  'difficultyGettingAroundInPublic',
  'difficultyDressingYourself',
  'feltDepressed',
  'problemsWithYourClosePersonalRelationships',
  'problemsWithYourConcentrationWhenReadingOrWatchingTV',
  'unableToCommunicateWithPeopleProperly',
  'painfulMuscleCrampsOrSpasms',
  'embarrassedInPublicDueToHavingParkinsonsDisease',
]);

export const PDQ8Form = (): React.JSX.Element => {
  const myServContext = React.useContext(MyServiceContext);
  const { editing, setEditingData, locale, viewing } = myServContext;
  const pdq8Data = (editing?.data || viewing?.data) as IPDQ8 & IControlProps;
  const pdq8Locales = pdq8.localizations;
  const useLocale = isLocaleKey(locale) ? locale : 'fi';
  const onChangeSurveyForm = (values: TOnChangeValues): void => {
    const field = Object.keys(values)[0];
    const value = Object.values(values)[0];
    setEditingData?.({
      ...pdq8Data,
      [field]: value,
    });
  };

  return (
    <>
      <BlockWrapper title={viewing ? formatPartialDate(pdq8Data.date) : undefined}>
        {!viewing && (
          <FormRow title={'general.date'}>
            <InputHandler
              name="date"
              type="PartialDate"
              dateDefault="now"
              formData={{
                document: { date: pdq8Data?.date },
                onChange: onChangeSurveyForm,
              }}
              editing={!!editing}
            />
          </FormRow>
        )}
        <FormRow bottomMargin={false} condition={!!viewing}>
          <div style={{ color: colors.tertiaryText, marginBottom: '1rem' }}>
            {['never', 'occassionally', 'sometimes', 'often', 'alwaysOrCannotDoAtAll'].map((opt, i) => {
              return <span key={opt}>{(i === 0 ? '' : ' / ') + pdq8Locales[useLocale][`opts.${opt}`]}</span>;
            })}
          </div>
        </FormRow>
        <div style={{ marginBottom: '3rem' }}>
          {fields.map((f) => {
            return (
              <FormRow key={f} title={pdq8Locales[useLocale][f]} formatTitle={false}>
                <InputHandler
                  name={f}
                  type="Radio"
                  editing={!!editing}
                  options={['never', 'occassionally', 'sometimes', 'often', 'alwaysOrCannotDoAtAll']}
                  optionFormatter={(o) => pdq8Locales[useLocale][`opts.${o}`]}
                  formData={{
                    document: { [f]: pdq8Data?.[f] },
                    onChange: onChangeSurveyForm,
                  }}
                />
              </FormRow>
            );
          })}
        </div>
        <FormRow title="myService.score">
          {pdq8.calculators.isFilledPdq8(pdq8Data).state === Task_Progress.State.Completed
            ? Math.round(
                ((pdq8.calculators.isFilledPdq8(pdq8Data).yielded ?? 1) /
                  (pdq8.calculators.isFilledPdq8(pdq8Data).ceiling ?? 1)) *
                  100,
              ) + ' %'
            : '-'}
        </FormRow>
      </BlockWrapper>
    </>
  );
};
