/**
 * Checkbox component that can be used when a single checkbox is needed to toggle between true/false values
 */

import { Checkbox } from '@mui/material';
import { styled } from '@mui/system';
import { FormControlLabel } from '@mui/material';
import * as React from 'react';

import colors from '../../../config/theme/colors';
import { historyValue, styleDocument } from '../../../config/theme/componentTheme';

const StyledDiv = styled('div')({
  marginTop: '-0.9rem', // Offset checkbox default padding
  '* > &:not(:last-of-type)': {
    marginBottom: '0.5rem',
  },
});

const StyledCheckbox = styled(Checkbox, { shouldForwardProp: (prop) => prop !== 'labelFirst' })(
  ({ labelFirst }: { labelFirst?: boolean }) => ({
    position: 'relative',
    left: labelFirst ? 0 : '-1rem',
    margin: 0,
  }),
);

const StyledLabel = styled('div', {
  shouldForwardProp: (prop) => prop !== 'labelColor',
})(({ labelColor }: { labelColor?: string }) => ({
  fontSize: styleDocument.fontSize,
  color: labelColor ? labelColor : colors.primaryText,
}));

const isNotUndefined = (n: any): boolean => (n || n === false ? true : false);

const textElements = (
  value?: string,
  optionFormatter?: (id: string | number) => React.JSX.Element | string,
): React.JSX.Element => {
  return (
    <StyledDiv>
      <StyledDiv>{isNotUndefined(value) && optionFormatter ? optionFormatter(value!) : value ? value : '-'}</StyledDiv>
    </StyledDiv>
  );
};

const onChangeCheckbox =
  (name: string, onChange: IInputBasics['onChange']) =>
  (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (onChange) {
      if (e.currentTarget.checked) {
        onChange({ [name]: true });
      } else {
        onChange({ [name]: false });
      }
    }
  };

const CheckboxElements = ({
  value,
  name,
  option,
  onChange,
  optionFormatter,
  showLabel,
  labelColor,
  disableCheckboxPadding = false,
  labelFirst = false,
  disabled,
}: {
  value?: string;
  name: string;
  option?: string;
  onChange?: IInputBasics['onChange'];
  optionFormatter?: (name: string | number) => React.JSX.Element | string;
  showLabel?: boolean;
  labelColor?: string;
  disableCheckboxPadding?: boolean;
  labelFirst?: boolean;
  disabled?: boolean;
}): React.JSX.Element => {
  const checked = option === value;
  return (
    <StyledDiv>
      <FormControlLabel
        style={{ margin: '0' }}
        control={
          <StyledCheckbox
            checked={checked}
            value={option}
            onChange={onChangeCheckbox(name, onChange)}
            color="primary"
            style={{ padding: disableCheckboxPadding ? 0 : undefined }}
            labelFirst={labelFirst}
            disabled={disabled}
          />
        }
        label={
          showLabel ? (
            <StyledLabel labelColor={labelColor}>
              {optionFormatter && option ? optionFormatter(option) : option}
            </StyledLabel>
          ) : (
            ''
          )
        }
        labelPlacement={labelFirst ? 'start' : 'end'}
      />
    </StyledDiv>
  );
};
const CheckboxSingle = ({
  editing = false,
  value,
  optionFormatter,
  ...props
}: IInputBasics & ICheckboxSingle): React.JSX.Element => {
  return !editing ? (
    <div style={historyValue}>{textElements(value, optionFormatter)}</div>
  ) : (
    <div>
      <CheckboxElements value={value} optionFormatter={optionFormatter} {...props} />
    </div>
  );
};

export default CheckboxSingle;
