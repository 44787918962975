import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { styleDocument } from '../../config/theme/componentTheme';
import { Container, Item } from '../Grid';
import { styled } from '@mui/system';

const StyledHeader = styled('div')({
  color: styleDocument.documentHeader.color,
  ...styleDocument.documentHeader.other,
  marginBottom: '6rem',
});

const IconArea = styled('div')({
  width: '9rem',
});

const InfoText = styled('div')({
  fontWeight: 'normal',
  fontSize: '1.6rem',
  color: styleDocument.documentHeader.infoTextColor,
});

const SecondaryButtonArea = styled('div', { shouldForwardProp: (prop) => prop !== 'iconPadding' })(
  ({ iconPadding }: { iconPadding: boolean }) => ({
    paddingLeft: iconPadding ? '9rem' : '0',
    ...styleDocument.documentHeader.secondary,
    borderTop: styleDocument.documentHeader.border,
  }),
);

const SecondaryButtonRow = styled(Container)({
  paddingTop: '2rem',
  '* > &:not(:last-of-type)': {
    borderBottom: styleDocument.documentHeader.border,
    paddingBottom: '4rem',
  },
});

const SecondaryTitle = styled('div')({});

const InfoTextComponent = ({ infoText }: { infoText: string | React.JSX.Element }) => (
  <>
    {typeof infoText === 'string' ? (
      <InfoText>
        <FormattedMessage
          id={infoText}
          values={{
            strong: (chunks) => <div style={{ fontWeight: '600' }}>{chunks}</div>,
            br: <br />,
          }}
        />
      </InfoText>
    ) : (
      <InfoText>{infoText}</InfoText>
    )}
  </>
);

const DocumentHeader = ({
  headerId,
  header,
  headerWidth = 7,
  editing,
  icon,
  editButtons,
  info,
  infoText,
  secondaryButtons,
}: IOwnProps): React.JSX.Element => {
  React.useEffect(() => {
    // Scroll automatically to the top when changing from historyview to editing and back
    window.scrollTo(0, 0);
  }, [editing]);
  return (
    <StyledHeader key={headerId + 'header'}>
      <Container alignItems="center">
        {icon && <IconArea>{icon}</IconArea>}
        <Item xs={headerWidth}>
          {header ? (
            header
          ) : (
            <FormattedMessage
              id={headerId}
              values={{
                br: <br />,
              }}
            />
          )}
          {infoText && <InfoTextComponent infoText={infoText} />}
          {info && <IconArea style={{ display: 'inline-flex' }}>{info}</IconArea>}
        </Item>
        {editButtons && (
          <Item xs={12 - headerWidth > 0 ? 12 - headerWidth : true}>
            <Container justifyContent="flex-end">
              <Item>{!editing && editButtons}</Item>
            </Container>
          </Item>
        )}
      </Container>
      {!editing && Array.isArray(secondaryButtons) && (
        <SecondaryButtonArea iconPadding={!!icon}>
          {secondaryButtons?.map((b) => (
            <SecondaryButtonRow key={b.headerId} alignItems="center">
              <Item xs={true}>
                <SecondaryTitle>
                  <FormattedMessage id={b.headerId} />
                  {b.infoText && <InfoTextComponent infoText={b.infoText} />}
                </SecondaryTitle>
              </Item>
              <Item xs={12} md={5}>
                <Container justifyContent="flex-end">
                  <Item>{!editing && b.button}</Item>
                </Container>
              </Item>
            </SecondaryButtonRow>
          ))}
        </SecondaryButtonArea>
      )}
    </StyledHeader>
  );
};

interface IOwnProps {
  name?: string;
  headerId?: string;
  /** Overrides headerId */
  header?: string | React.JSX.Element;
  headerWidth?: number;
  editing?: string;
  icon?: React.JSX.Element;
  editButtons?: React.JSX.Element | React.JSX.Element[];
  /** Possible info icon */
  info?: React.JSX.Element;
  /** Info text below header */
  infoText?: string | React.JSX.Element;
  secondaryButtons?: Array<{ headerId: string; infoText?: string | React.JSX.Element; button: React.JSX.Element }>;
}

export default DocumentHeader;
