import DataTable from 'Components/DataTable';
import InputHandler from 'Components/InputHandler';
import BlockWrapper from 'Components/_NewElements/BlockWrapper';
import QuestionAnswerPair from 'Components/_NewElements/QuestionAnswerPair';
import { pedsql, IPedsQL, isLocaleKey } from 'neuro-schemas';
import { exists, formatPartialDate } from 'neuro-utils';
import * as React from 'react';
import { MyServiceContext } from '../..';
import colors from '../../../../../config/theme/colors';
import { Container, Item } from 'Components/Grid';
import { styled } from '@mui/material';
import FormRow from 'Components/FormRow';

const StyledHeader = styled('div')({
  color: `${colors.primary}`,
  fontSize: '2rem',
  fontWeight: 600,
});

// Neuromuscular

const pedsqlNeuromuscularIllness = [
  'hardToBreathe',
  'getIllEasily',
  'getSoresAndRashes',
  'legsHurt',
  'feelTired',
  'backFeelsStiff',
  'wakeUpTired',
  'handsAreWeak',
  'hardToUseToilet',
  'hardToGainOrLoseWeight',
  'hardToUseHands',
  'hardToSwallowFood',
  'longTimeToBathOrShower',
  'getHurtAccidentally',
  'takeLongTimeToEat',
  'hardToTurnAtNight',
  'hardToGoPlacesWithEquipment',
] as Array<keyof IPedsQL<'neuromuscular', 'adult', 'selfReport'>['neuromuscularIllness']>;

const pedsqlCommunication = [
  'hardToTellDoctorsHowIFeel',
  'hardToAskDoctorsQuestions',
  'hardToExplainIllnessToOthers',
] as Array<keyof IPedsQL<'neuromuscular', 'adult', 'selfReport'>['communication']>;

const pedsqlFamilyCircumstances = [
  'hardForFamilyToPlanActivities',
  'hardForFamilyToGetEnoughRest',
  'moneyIsAProblem',
  'familyHasManyProblems',
  'doNotHaveNecessaryEquipment',
] as Array<keyof IPedsQL<'neuromuscular', 'adult', 'selfReport'>['familyCircumstances']>;

// Fatigue

const pedsqlGeneralFatigue = [
  'feelTired',
  'feelPhysicallyWeak',
  'feelTooTiredToDoThings',
  'feelTooTiredToSpendTimeWithFriends',
  'haveTroubleFinishingThings',
  'haveTroubleStartingThings',
] as Array<keyof IPedsQL<'fatigue', 'adult', 'selfReport'>['generalFatigue']>;

const pedsqlSleepRestFatigue = [
  'sleepALot',
  'hardToSleepThroughNight',
  'feelTiredWhenWakingUp',
  'restALot',
  'takeALotOfNaps',
  'spendALotOfTimeInBed',
] as Array<keyof IPedsQL<'fatigue', 'adult', 'selfReport'>['sleepRestFatigue']>;

const pedsqlMentalFatigue = [
  'hardToKeepAttention',
  'hardToRememberWhatPeopleTellMe',
  'hardToRememberWhatIJustHeard',
  'hardToThinkQuickly',
  'troubleRememberingWhatIWasJustThinking',
  'troubleRememberingMoreThanOneThingAtATime',
] as Array<keyof IPedsQL<'fatigue', 'adult', 'selfReport'>['mentalFatigue']>;

/**
 * 0 - never a problem
 * 1 - almost never a problem
 * 2 - sometimes a problem
 * 3 - often a problem
 * 4 - almost always a problem
 */
const mapToAnswer = (value: number) => {
  return { 0: 'never', 1: 'almostNever', 2: 'sometimes', 3: 'often', 4: 'almostAlways' }[value];
};

export const PEDSQL = (): React.JSX.Element => {
  const myServContext = React.useContext(MyServiceContext);
  const { fm, locale, viewing } = myServContext;

  const pedsqlData = viewing?.data as IPedsQL & IControlProps;
  const pedsqlLocales = pedsql.localizations;
  const useLocale = isLocaleKey(locale) ? locale : 'en';

  const version = pedsqlData.version || 'adult';
  const type = pedsqlData.type || 'selfReport';
  const module = pedsqlData.module;

  const versionTextId = pedsql.getVersionText(type, version, module);

  type TSection = {
    key: string;
    fields: string[];
    titleKey: string;
  };

  const getNeuromuscularSections = (ageVer: IPedsQL['version']): Array<TSection> => [
    {
      key: 'neuromuscularIllness',
      fields: pedsqlNeuromuscularIllness,
      titleKey: 'neuromuscularIllness.title',
    },
    {
      key: 'communication',
      fields: pedsqlCommunication,
      titleKey: 'communication.title',
    },
    {
      key: 'familyCircumstances',
      fields: pedsqlFamilyCircumstances,
      titleKey: `familyCircumstances.${ageVer}.title`,
    },
  ];

  const getFatigueFields = (ageVer: IPedsQL['version']): Array<TSection> => {
    return [
      {
        key: 'generalFatigue',
        fields: pedsqlGeneralFatigue,
        titleKey: `generalFatigue.${ageVer}.title`,
      },
      {
        key: 'sleepRestFatigue',
        fields: pedsqlSleepRestFatigue,
        titleKey: `sleepRestFatigue.${ageVer}.title`,
      },
      {
        key: 'mentalFatigue',
        fields: pedsqlMentalFatigue,
        titleKey: `mentalFatigue.${ageVer}.title`,
      },
    ];
  };

  const getSections = (mod: IPedsQL['module'], ageVer: IPedsQL['version']) => {
    if (mod === 'neuromuscular') return getNeuromuscularSections(ageVer);
    if (mod === 'fatigue') return getFatigueFields(ageVer);
    return undefined;
  };

  if (!pedsqlData.module || !pedsqlData.version || !getSections(pedsqlData.module, pedsqlData.version)) {
    return <></>;
  }

  const sections = getSections(pedsqlData.module, pedsqlData.version);

  if (
    pedsqlData.module === 'neuromuscular' &&
    pedsqlData.version === 'youngChild' &&
    pedsqlData.type === 'selfReport'
  ) {
    sections?.splice(1, 2);
  }

  return (
    <>
      <div style={{ marginBottom: '4rem', marginTop: '-2rem' }}>
        {versionTextId && (
          <QuestionAnswerPair question={fm('general.version')} answer={pedsqlLocales[useLocale][versionTextId]} />
        )}
      </div>

      <BlockWrapper title={viewing ? formatPartialDate(pedsqlData.date) : undefined}>
        {sections?.map((section) => {
          return (
            <div key={section.key}>
              <Container style={{ justifyContent: 'space-between', alignItems: 'center', marginTop: '2rem' }}>
                <Item xs={10}>
                  <StyledHeader>{pedsqlLocales[useLocale][`${type}.${section.titleKey}`]}</StyledHeader>
                </Item>
                <Item xs={2} />
              </Container>
              <DataTable
                headers={['', '', '']}
                data={{
                  rowData: section.fields.map((f) => {
                    const k = section.key;
                    const sectionData = pedsqlData?.[k as keyof typeof pedsqlData];
                    const fieldData = sectionData?.[f as keyof typeof sectionData] as unknown as number;
                    return [
                      pedsqlLocales[useLocale][
                        `${type}.${k}${
                          module === 'fatigue' || section.key === 'familyCircumstances' ? `.${pedsqlData.version}` : ''
                        }.${f}`
                      ],
                      <InputHandler
                        key={f}
                        name={f}
                        type="NumberField"
                        editing={false}
                        width={8}
                        formData={{
                          document: { [f]: fieldData },
                        }}
                      />,
                      <div key={`${f}Info`} style={{ marginLeft: '2rem' }}>
                        {exists(fieldData) ? pedsqlLocales[useLocale][`opts.${mapToAnswer(fieldData)}`] : '-'}
                      </div>,
                    ];
                  }),
                  rowStyle: section.fields.map(() => {
                    return { cellStyle: [{ width: '41%' }, { width: '8%' }, { width: '51%' }] };
                  }),
                }}
              />
            </div>
          );
        })}
        <div style={{ marginTop: '2rem' }}>
          <FormRow title={'myService.score'}>
            <div style={{ fontWeight: 600 }}>
              {`${
                pedsql.calculators.pedsqlTotalScore(pedsqlData).yielded ||
                pedsql.calculators.pedsqlTotalScore(pedsqlData).yielded === 0
                  ? pedsql.calculators.pedsqlTotalScore(pedsqlData).yielded
                  : '-'
              }`}
              {' / '}
              {`${pedsql.calculators.pedsqlTotalScore(pedsqlData).ceiling || '-'}`}
            </div>
          </FormRow>
        </div>
      </BlockWrapper>
    </>
  );
};
