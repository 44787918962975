/**
 * TileContentMaker
 * Creates a component with specific props based on type.
 * Use _MultipleRows types when more specs require the title/value to be in some custom style
 */

import BigHeaderInfo, { IBigHeaderInfo } from './components/BigHeaderInfo';
import ListWithSubheaders, { IListWithSubheaders } from './components/ListWithSubheaders';
import RecentList, { IRecentList } from './components/RecentList';
import RecentListMultipleRows, { IRecentListMultipleRows } from './components/RecentListMultipleRows';
import SpecificList, { ISpecificList } from './components/SpecificList';
import SpecificListMultipleRows, { ISpecificListMultipleRows } from './components/SpecificListMultipleRows';
import SpecificListNoDate, { ISpecificListNoDate } from './components/SpecificListNoDate';

const TileContentMaker = ({ ...props }: TTileContent) => {
  const type = props.type;
  switch (type) {
    case 'bigHeaderInfo':
      return <BigHeaderInfo {...props} />;
    case 'recentList':
      return <RecentList {...props} />;
    case 'specificList':
      return <SpecificList {...props} />;
    case 'listWithSubheaders':
      return <ListWithSubheaders {...props} />;
    case 'recentListMultipleRows':
      return <RecentListMultipleRows {...props} />;
    case 'specificListMultipleRows':
      return <SpecificListMultipleRows {...props} />;
    case 'specificListNoDate':
      return <SpecificListNoDate {...props} />;
    default:
      return null;
  }
};

type TTile =
  | 'bigHeaderInfo'
  | 'recentList'
  | 'specificList'
  | 'listWithSubheaders'
  | 'recentListMultipleRows'
  | 'specificListMultipleRows'
  | 'specificListNoDate';
type TTileContent = { type: TTile } & (
  | IBigHeaderInfo
  | IRecentList
  | ISpecificList
  | IListWithSubheaders
  | IRecentListMultipleRows
  | ISpecificListMultipleRows
  | ISpecificListNoDate
);

export default TileContentMaker;
