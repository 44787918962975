import ActionButtonRounded from 'Components/ActionButtonRounded';
import DataTable from 'Components/DataTable';
import { Container, Item } from 'Components/Grid';
import HistoryRowSubHeader from 'Components/HistoryRowSubHeader';
import {
  formatPartialDate,
  nowPartialDate,
  partialDateFromDate,
  partialDateToValue,
  sortPartialDate,
} from 'neuro-utils';
import * as React from 'react';
import { MyServiceContext } from '..';
import NewTreatmentDecisionCell from './components/treatmentDecisionCell';
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'Store/index';
import { actions } from 'Store/myapp/actions';
import ConfirmationDialog from 'Components/ConfirmationDialog';
import colors from '../../../../config/theme/colors';
import { styled } from '@mui/system';
import { surveysByPlatform } from '../config';
import { Clear, Replay } from '@mui/icons-material';
import RecurringTasksList from './components/RecurringTasksList';
import { getNextRecurringDeadline } from 'Routes/MyService/util';
import InputHandler from 'Components/InputHandler';

const ClearIconStyled = styled(Clear)({
  display: 'block',
  width: '3rem',
  color: colors.secondaryText,
  cursor: 'pointer',
  alignSelf: 'center',
  '&:hover': {
    color: 'red',
  },
});

const TaskListHistory = ({
  treatmentDecisionDocs,
  taskLists,
}: {
  treatmentDecisionDocs: Array<ININMTTreatmentPeriod>;
  taskLists: Array<ITaskList>;
}): React.JSX.Element => {
  const myServContext = React.useContext(MyServiceContext);
  const { fm, setEditingObj, platform, locale } = myServContext;
  const [showPast, setShowPast] = React.useState<boolean>(false);

  const historyData: Array<ININMTTreatmentPeriod | ITaskList> = [
    ...(platform === 'ninmt' ? treatmentDecisionDocs : []),
    ...taskLists
      .map((tl) => {
        const tlCopy = structuredClone(tl);
        if (tlCopy.end_time && !tlCopy.deadline) tlCopy.deadline = partialDateFromDate(new Date(tlCopy.end_time));
        return tlCopy;
      })
      .filter(
        (tl) =>
          tl.tasks?.every(
            (t) =>
              platform &&
              surveysByPlatform[platform]
                ?.flatMap((s) =>
                  s === 'pedsql'
                    ? ['pedsqlNeuromuscular', 'pedsqlFatigue']
                    : s === 'treatmentEfficiency'
                      ? ['treatmentEfficiencyBefore', 'treatmentEfficiencyAfter']
                      : s,
                )
                ?.includes(t),
          ) &&
          (partialDateToValue(tl.deadline) >= partialDateToValue(nowPartialDate()) || showPast),
      ),
  ];

  const taskOrgSettings = useSelector((s: IState) => s.settings?.orgSettings?.settings?.taskUiSettings);

  const dispatch = useDispatch();

  const [deleteId, setDeleteId] = React.useState<string | null>(null);

  const historyDataToTable = historyData
    .sort((a, b) =>
      sortPartialDate(
        'deadline' in b ? b.deadline : 'date' in b ? b.date : undefined,
        'deadline' in a ? a.deadline : 'date' in a ? a.date : undefined,
      ),
    )
    .map((data, i) => {
      if ('date' in data && data.date && platform === 'ninmt') {
        return <NewTreatmentDecisionCell fm={fm} date={data.date} key={i} />;
      } else if ('deadline' in data || 'recurringTaskDeadline' in data) {
        const tasks = data.tasks?.map((t) => fm(`myService.${platform}.opts.${t}`)).join(', ');
        const title =
          platform && data?.title && taskOrgSettings?.[platform]?.[data?.title]?.titleLocalized
            ? taskOrgSettings[platform]?.[data?.title]?.titleLocalized?.[locale] ??
              taskOrgSettings[platform]?.[data?.title]?.titleLocalized?.['fi']
            : data?.title === 'other'
              ? fm('myService.opts.other')
              : data?.title
                ? data.title
                : '';
        return [
          <div key="deadline" style={{ whiteSpace: 'nowrap', paddingRight: '0.5rem' }}>
            {data.recurring && data.weeklySurveyInterval && data.recurringTaskDeadline
              ? formatPartialDate(getNextRecurringDeadline(data.recurringTaskDeadline, data.weeklySurveyInterval))
              : formatPartialDate(data.deadline)}
          </div>,
          title,
          tasks ?? '-',
          data.recurring ? (
            <div style={{ display: 'flex' }}>
              <Replay
                fontSize="small"
                sx={{
                  color:
                    partialDateToValue(data?.deadline) > partialDateToValue(nowPartialDate())
                      ? colors.success
                      : undefined,
                }}
              />
            </div>
          ) : (
            ''
          ),
          <div key="sendDate" style={{ whiteSpace: 'nowrap' }}>
            {formatPartialDate(data.sendDate)}
          </div>,
          <div key={`${i}${data.id ?? ''}`} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ActionButtonRounded
              text="general.edit"
              filled={true}
              width={9.6}
              height={2.5}
              fontSize={16}
              uppercase={false}
              onClick={() => {
                setEditingObj({ type: 'taskList', data: data });
              }}
              disabled={data.tasks?.some((task) => task.indexOf('treatmentEfficiency') > -1)}
            />
            <ClearIconStyled onClick={() => setDeleteId(data.id)} />
          </div>,
        ];
      } else return 'filter';
    })
    .filter((d) => d !== 'filter');

  const patientContactInfo = useSelector((s: { patient: IPatientStore }) => s.patient.contactInfo);

  const patientMySQStatus = useSelector((s: IState) => s.myapp.mysqUserId);
  const sentInvites = useSelector((s: IState) => s.myapp.sentInvites);

  const recurringTasks = historyData.filter(
    (d) => 'recurring' in d && d.recurring && partialDateToValue(d?.deadline) > partialDateToValue(nowPartialDate()),
  ) as Array<ITaskList>;

  return (
    <>
      <Container style={{ justifyContent: 'space-between' }}>
        <Item>
          <HistoryRowSubHeader header={fm('myService.taskLists')} />
        </Item>
        <Item>
          <ActionButtonRounded
            text="myService.newTaskList"
            filled={true}
            uppercase={false}
            width={9.6}
            height={3}
            fontSize={16}
            onClick={() => {
              setEditingObj({
                type:
                  !patientMySQStatus &&
                  (!sentInvites || sentInvites.length === 0) &&
                  (!taskLists || taskLists.length === 0)
                    ? 'invite'
                    : 'taskList',
                data: null,
              });
            }}
            disabled={!!patientContactInfo?.contactNotAllowed}
            disabledTooltip={<span>{fm('myService.newTaskListDisabledTooltip')}</span>}
          />
        </Item>
      </Container>
      {platform && recurringTasks && recurringTasks.length > 0 && (
        <>
          <Container>
            <Item xs={9} md={7}>
              <RecurringTasksList platform={platform} recurringTasks={recurringTasks} showEndButton />
            </Item>
          </Container>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <HistoryRowSubHeader header={fm('myService.all')} fontSize="small" />
            <div style={{ marginRight: '-0.5rem' }}>
              <InputHandler
                type="CheckboxSingle"
                name="showPast"
                editing={true}
                value={showPast ? 'showPast' : ''}
                option={'showPast'}
                showLabel
                formData={{
                  onChange: () => setShowPast(!showPast),
                  document: { showPast: showPast },
                }}
                optionFormatter={() => fm('myService.showPastTasks')}
                labelFirst
              />
            </div>
          </div>
        </>
      )}
      {!recurringTasks ||
        (recurringTasks.length === 0 && (
          <div style={{ display: 'flex', marginRight: '-0.5rem', justifyContent: 'end' }}>
            <InputHandler
              type="CheckboxSingle"
              name="showPast"
              editing={true}
              value={showPast ? 'showPast' : ''}
              option={'showPast'}
              showLabel
              formData={{
                onChange: () => setShowPast(!showPast),
                document: { showPast: showPast },
              }}
              optionFormatter={() => fm('myService.showPastTasks')}
              labelFirst
            />
          </div>
        ))}
      <DataTable
        headers={['deadline', 'taskListTitle', 'surveysAndDiaries', '', 'sendDate', 'hidden']}
        headersFormatter={(h) => (h === 'hidden' || h === '' ? '' : fm(`myService.${h}`))}
        data={{ rowData: historyDataToTable }}
      />
      <ConfirmationDialog
        open={!!deleteId}
        text={fm('general.reallyWantToDelete')}
        cancel={{ callback: () => setDeleteId(null) }}
        confirm={{
          callback: () => {
            const deleteList = taskLists.find((d) => d.id === deleteId);
            deleteList && actions.deleteTasklist(deleteList, dispatch);
            setDeleteId(null);
          },
        }}
      />
    </>
  );
};

export default TaskListHistory;
