import {
  IBAI,
  IBDI,
  IContraIndicationsInquiry,
  ICOPD,
  IDES,
  IDEPS,
  IDeviceTherapyControlVisitInquiry,
  IDSS,
  IEQ5D,
  IESS,
  IISI,
  ISleepApneaFirstVisitInquiry,
  Task_Progress,
  ISupplyShortagesInquiry,
  IPedsQL,
  INMSQ,
  IMdsUpdrsIV,
  pdq8,
  ITreatmentEfficiency,
} from 'neuro-schemas';
import { pick } from 'ramda';
import { omitControlProps } from 'Utility/documentHandling';
import { surveysByPlatform } from '../Document/config';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Warning } from '@mui/icons-material';
import store from 'Store/index';
import { IGroupACLMapping } from '../../../config/definitions/accessControl';
import PlatformCapabilities from '../../../config/capabilities';
import { assertCapabilities } from 'Store/index';
import { nowPartialDate, partialDateFromDate, partialDateToValue } from 'neuro-utils';
import { isEmpty } from 'Utility/ramdaReplacement';

// Sanitize bdi document for calculator
export const bdiIncludeOnlyNumbers = (bdi: IBDI & IControlProps): IBDI => {
  if (!bdi) return {};
  const keys = Object.keys(bdi);
  const numberkeys: Array<keyof IBDI> = keys.filter((k) => !isNaN(parseInt(k))) as Array<keyof IBDI>;
  numberkeys.push('date');
  return pick(numberkeys, bdi);
};

const typeIsSurveyType = (type: string): type is keyof Task_Progress.TTaskDocType =>
  Object.values(surveysByPlatform).flat().includes(type);

const getSurveyStatus = (surveyDoc: TSurveyType): boolean | undefined => {
  if (typeof surveyDoc !== 'object' || !typeIsSurveyType(surveyDoc._type)) return undefined;

  const type: keyof Task_Progress.TTaskDocType = surveyDoc._type;
  const data: Task_Progress.TTaskDocType[keyof Task_Progress.TTaskDocType] = omitControlProps(surveyDoc);

  return Task_Progress.calculateProgress(type, data).state === Task_Progress.State.Completed;
};

const makeDocumentHeader = (type: string) => {
  switch (type) {
    case 'invite':
      return 'myService.invite';
    case 'taskList':
      return 'myService.taskList';
    case 'bdi':
      return 'myService.ninmt.bdi';
    case 'bai':
      return 'myService.ninmt.bai';
    case 'ninmtPreInquiry':
      return 'myService.ninmt.ninmtPreInquiry.title';
    case 'contraIndicationsInquiry':
      return 'myService.ninmt.contraIndicationsInquiry';
    case 'treatmentMonitoringInquiry':
      return 'myService.ninmt.treatmentMonitoringInquiry.title';
    case 'tdcsReport':
      return 'myService.ninmt.tdcsReport';
    case 'treatmentEfficiency':
      return 'myService.ninmt.treatmentEfficiency';
    case 'supplyShortagesInquiry':
      return 'myService.ninmt.supplyShortagesInquiry';
    case 'copd':
      return 'myService.sleepApnea.copd.title';
    case 'deps':
      return 'myService.sleepApnea.deps.title';
    case 'des':
      return 'myService.sleepApnea.des.title';
    case 'dss':
      return 'myService.sleepApnea.dss.title';
    case 'ess':
      return 'myService.sleepApnea.ess.title';
    case 'isi':
      return 'myService.sleepApnea.isi.title';
    case 'sleepApneaFirstVisitInquiry':
      return 'myService.sleepApnea.sleepApneaFirstVisitInquiry.title';
    case 'deviceTherapyControlVisitInquiry':
      return 'myService.sleepApnea.deviceTherapyControlVisitInquiry.title';
    case 'satisfactionWithCare':
      return 'myService.sleepApnea.satisfactionWithCare.title';
    case 'eq5d':
      return 'myService.sleepApnea.eq5d.title';
    case 'pedsql':
      return 'myService.sma.pedsql';
    case 'nmsq':
      return 'myService.parkinson.nmsq';
    case 'impulseControlSurvey':
      return 'myService.parkinson.impulseControlSurvey';
    case 'mdsUpdrsIV':
      return 'myService.parkinson.mdsUpdrsIV';
    case 'pdq8':
      return 'myService.parkinson.pdq8';
    case 'passQuestion':
      return 'myService.mgravis.passQuestion';
    case 'mgadl':
      return 'myService.mgravis.opts.mgadl';
    default:
      return 'myService.title';
  }
};

const makeDocumentEditingInfotext = (type: string, sentInvites: boolean) => {
  switch (type) {
    case 'taskList':
      return 'myService.taskListInfoText';
    case 'invite':
      return sentInvites ? 'myService.inviteInformationTextShort' : 'myService.inviteInformationText';
    default:
      return undefined;
  }
};

const makeDocumentInfotext = (
  platform?: Platform,
  otherProps?: { epilepsySeizuresNoNameByPatient?: boolean; docType?: string; docData?: TSurveyType; locale?: Locale },
): React.JSX.Element | undefined | string => {
  if (otherProps?.docType === 'pdq8' && otherProps.locale)
    return <p>{pdq8.localizations[otherProps.locale]['titleDesc']}</p>;
  switch (platform) {
    case 'epilepsy': {
      return otherProps?.epilepsySeizuresNoNameByPatient ? (
        <div style={{ display: 'flex' }}>
          <span style={{ margin: '0 0.5rem 0 0.3rem' }}>
            <Warning color="warning" />
          </span>
          <div>
            <FormattedMessage id="myService.epilepsy.epilepsySeizuresNoNameByPatient" />{' '}
            <FormattedMessage id="diagnosis.epilepsy.seizureType.seizureNameByPatientInfotext" />{' '}
            <FormattedMessage id="myService.epilepsy.epilepsySeizuresNoNameByPatient2" />
          </div>
        </div>
      ) : undefined;
    }
    case 'dmd':
    case 'sma':
      if (otherProps?.docType === 'pedsql') {
        if (otherProps.docData && 'module' in otherProps.docData && otherProps?.docData?.module === 'fatigue') {
          return `myService.${platform}.fatigue`;
        }
        if (otherProps.docData && 'module' in otherProps.docData && otherProps?.docData?.module === 'neuromuscular') {
          return `myService.${platform}.neuromuscular`;
        }
      }
      return undefined;
    default:
      return undefined;
  }
};

const isTreatmentPeriod = (document: ININMTTreatmentPeriod | TSurveyType): document is ININMTTreatmentPeriod =>
  document._type === 'ninmtTreatmentPeriod';

export type TSurveyType = (
  | IBAI
  | IBDI
  | ININMTPreInquiry
  | IContraIndicationsInquiry
  | ITreatmentMonitoringInquiry
  | ITreatmentEfficiency
  | ITDCSReport
  | ISupplyShortagesInquiry
  | ICOPD
  | IDEPS
  | IDES
  | IDSS
  | IESS
  | IISI
  | ISleepApneaFirstVisitInquiry
  | IDeviceTherapyControlVisitInquiry
  | IEQ5D
  | IPedsQL
  | INMSQ
  | IMdsUpdrsIV
) &
  IControlProps;

const getPlatformInviteFlavorString = () => {
  const platform = store.getState().session.platforms?.selected;
  if (!platform) return null;
  return `INVITE_DOWNLOAD_APP_${platform.toUpperCase()}`;
};

const matchesInviteFlavorString = (flavor: string) => {
  return flavor.substring(0, 19) === 'INVITE_DOWNLOAD_APP';
};

const checkMyServCapability = (platform: Platform, capabilityGroups: IGroupACLMapping): boolean => {
  const myServTileCapa = assertCapabilities([PlatformCapabilities.MYSERVICE_TILE], capabilityGroups);
  switch (platform) {
    case 'ninmt': {
      const myNinmtCapa = assertCapabilities([PlatformCapabilities.NINMT_MYSERVICE_TILE], capabilityGroups);
      return myServTileCapa && myNinmtCapa;
    }
    case 'sleepApnea': {
      const mySRCapa = assertCapabilities([PlatformCapabilities.SR_MYSERVICE_TILE], capabilityGroups);
      return myServTileCapa && mySRCapa;
    }
    case 'epilepsy': {
      const myEpilepsyCapa = assertCapabilities([PlatformCapabilities.EPILEPSY_MYSERVICE_TILE], capabilityGroups);
      return myServTileCapa && myEpilepsyCapa;
    }
    case 'sma': {
      const mySmaCapa = assertCapabilities([PlatformCapabilities.SMA_MYSERVICE_TILE], capabilityGroups);
      return myServTileCapa && mySmaCapa;
    }
    case 'dmd': {
      const myDmdCapa = assertCapabilities([PlatformCapabilities.DMD_MYSERVICE_TILE], capabilityGroups);
      return myServTileCapa && myDmdCapa;
    }
    case 'parkinson': {
      const myParkCapa = assertCapabilities([PlatformCapabilities.PARKINSON_MYSERVICE_TILE], capabilityGroups);
      return myServTileCapa && myParkCapa;
    }
    case 'mgravis': {
      const myMgravisCapa = assertCapabilities([PlatformCapabilities.MGRAVIS_MYSERVICE_TILE], capabilityGroups);
      return myServTileCapa && myMgravisCapa;
    }
  }
  return myServTileCapa;
};

/**
 * Checks if myService is available in toolbar and clickable in tile
 * @param {Platform} platform
 * @param {boolean} hasCapability Capability of the specific platform given as argument
 * @param docs All docs
 * @returns {boolean}
 */
const isMyServiceAvailable = (
  platform: Platform,
  hasCapability: boolean,
  docs?: Array<
    {
      [key: string]: any;
    } & IControlProps
  >,
) => {
  switch (platform) {
    case 'ninmt': {
      const indicationForTreatmentDocs = (docs?.filter((d) => d._type === 'indicationForTreatment') ??
        []) as Array<IIndicationForTreatment>;
      return hasCapability && indicationForTreatmentDocs?.some((ind) => ind?.indication && !isEmpty(ind?.indication));
    }
    case 'sleepApnea': {
      const diagDocs = (docs?.filter((d) => d._type === 'diagnosis') ?? []) as Array<IDiagnosis>;
      diagDocs?.some((diagDoc) =>
        ['G47.3', 'J96.1', 'J96.9', 'sleepApneaSuspicion', 'respiratoryFailureSuspicion'].includes(diagDoc?.diagnosis),
      );
      return (
        hasCapability &&
        diagDocs?.some((diagDoc) =>
          ['G47.3', 'J96.1', 'J96.9', 'sleepApneaSuspicion', 'respiratoryFailureSuspicion'].includes(
            diagDoc?.diagnosis,
          ),
        )
      );
    }
    case 'epilepsy': {
      const seizureTypeDocs = docs?.filter((d) => d._type === 'seizureType');
      return (
        hasCapability &&
        seizureTypeDocs?.some((seizureTypeDoc) => seizureTypeDoc?.nameByPatient && seizureTypeDoc?.classification)
      );
    }
    case 'ms': {
      return hasCapability;
    }
    case 'mgravis': {
      return hasCapability;
    }
    default: {
      return true;
    }
  }
};

const getNextRecurringDeadline = (
  firstDeadline: PartialDate,
  interval: ITaskList['weeklySurveyInterval'],
): PartialDate => {
  const firstDeadlineValue = partialDateToValue(firstDeadline);
  const dateNowPartialValue = partialDateToValue(nowPartialDate());
  if (dateNowPartialValue <= firstDeadlineValue) {
    return firstDeadline;
  }
  let intervalValue = 86400000 * 7;
  if (interval === 'everyOtherWeek') {
    intervalValue *= 2;
  } else if (interval === 'everyFourthWeek') {
    intervalValue *= 4;
  }
  let nextDeadlineValue = firstDeadlineValue;
  while (nextDeadlineValue < dateNowPartialValue) {
    nextDeadlineValue += intervalValue;
  }
  return partialDateFromDate(new Date(nextDeadlineValue));
};

const getMySQUsageStatus = (
  mysqUserId: string | null | undefined,
  delegates: Array<IDelegate> | undefined,
): 'patient' | 'delegate' | undefined => {
  if (mysqUserId) return 'patient';
  if (delegates && delegates.length > 0) return 'delegate';
  return;
};

export {
  getSurveyStatus,
  makeDocumentHeader,
  makeDocumentEditingInfotext,
  makeDocumentInfotext,
  isTreatmentPeriod,
  getPlatformInviteFlavorString,
  matchesInviteFlavorString,
  isMyServiceAvailable,
  checkMyServCapability,
  getNextRecurringDeadline,
  getMySQUsageStatus,
};
