import * as React from 'react';

import FormRow from '../FormRow';

import ToolTip from '../ToolTip';
import ActionButton from '../ActionButton';

import EditingEvent from './components/EditingEvent';
import {
  createEvent,
  deleteEvent,
  onChangeEvent,
  sortByDateAndSave,
  combinedEventsToSorted,
  TDeleteProps,
} from './utils';
import { IEventStepper, TEventStepperComponentProps, TTableView } from './definitions';
import ListView from './components/ListView';
import TableView from './components/TableView';
import ViewSelector from './components/ViewSelector';
import { fm } from 'Components/FormatMessage';
import StandAloneView from './components/StandAloneView';

export interface IEventStepperContext {
  thisEvents: any[];
  _thisEvents: any[];
  thisEventsLength: number | undefined;
  anchor: null | { anchor: HTMLElement; index: number };
  editingEvent: number | boolean;
  editingEventType: string | undefined;
  editingEvents: React.MutableRefObject<any[] | undefined>;
  editingIndex: number | undefined;
  setEditingIndex: React.Dispatch<React.SetStateAction<number | undefined>>;
  setEditing: (i: number, t?: string) => (e: React.MouseEvent) => void;
  save: (n?: string) => void;
  activeStep: number;
  activateStep: (i: number, enableAllClosed?: true) => () => void;
  toggleMenu: (index: number) => (e: React.MouseEvent<any>) => void;
  openDeleteDialog: (i: number, t?: string) => (e: React.MouseEvent) => void;
  deleteIndex: number | null;
  setDeleteIndex: React.Dispatch<React.SetStateAction<number | null>>;
  deleteConfirmCallback: (deleteProps?: TDeleteProps) => void;
  deleteCancelCallback: () => void;
  activeView: TTableView;
  setActiveView: React.Dispatch<React.SetStateAction<TTableView>>;
}

export const EventStepperContext = React.createContext<IEventStepperContext>({
  thisEvents: [],
  _thisEvents: [],
  thisEventsLength: undefined,
  anchor: null,
  editingEvent: false,
  editingEventType: undefined,
  editingEvents: { current: undefined } as React.MutableRefObject<any[] | undefined>,
  editingIndex: undefined,
  setEditingIndex: () => {},
  setEditing: () => () => {},
  save: () => {},
  activeStep: 0,
  activateStep: () => () => {},
  toggleMenu: () => () => {},
  openDeleteDialog: () => () => {},
  deleteIndex: null,
  setDeleteIndex: () => {},
  deleteConfirmCallback: () => {},
  deleteCancelCallback: () => {},
  activeView: 'list',
  setActiveView: () => {},
});

export const EventStepperComponent = (props: TEventStepperComponentProps): React.JSX.Element => {
  /** For EventStepperGroups: track what type of event is being edited */
  const [editingEventType, setEditingEventType] = React.useState<string | undefined>(undefined);

  const { type } = props;

  const isGroup = type === 'group';

  const propsByType = isGroup ? props.propsByType : {};
  const types = isGroup ? Object.keys(propsByType) : [];
  const combinedEvents = isGroup ? props.combinedEvents : [];

  const normalizedProps = isGroup ? props.propsByType[types[0]] : props;

  const {
    name,
    formData,
    viewing = false,
    separateLatest,
    allClosed,
    addNewTextHeader,
    previousEventsTextHeader,
    noPreviousEventsTextHeader,
    editingModeOn,
    deleteExtraAction,
    saveExtraAction,
    cancelExtraAction,
    addNewExtraAction,
    startEditExtraAction,
    tableContent,
    defaultView,
    readOnlyEvents = [],
  } = normalizedProps;

  /** Determine which props to use: normal props for single EventSteppers, propsByType for EventStepperGroups */
  const propsToUse = (type?: string) => {
    if (!isGroup) return normalizedProps;
    return type ? propsByType[type] : propsByType[types[0]];
  };

  /** Extra element to show above stepper */
  const stepperExtraElement = propsToUse(editingEventType).stepperExtraElement;

  /** Where the menu will spawn */
  const [anchor, setAnchor] = React.useState<null | { anchor: HTMLElement; index: number }>(null);
  /** Used to determine whether list or table view is active */
  const [activeView, setActiveView] = React.useState<TTableView>(defaultView ? defaultView : 'list');
  /** Render control */
  const [thisEventsLength, setThisEventsLength] = React.useState<number | undefined>(undefined);

  const toggleMenu =
    (index: number) =>
    (e: React.MouseEvent<any>): void => {
      setAnchor(!anchor ? { anchor: e.currentTarget, index } : null);
      allClosed && e.stopPropagation();
    };

  /** Track if editing or adding new (true means new event, otherwise number is event index) */
  const [editingEvent, setEditingEvent] = React.useState<boolean | number>(editingModeOn ? true : false);

  /** Active / open step for stepper */
  const [activeStep, setActiveStep] = React.useState<number>(separateLatest || allClosed ? -1 : 0);

  const activateStep = (i: number, enableAllClosed?: true) => (): void => {
    if ((separateLatest ? i - 1 : i) !== activeStep) setActiveStep(separateLatest ? i - 1 : i);
    else if (allClosed && enableAllClosed) {
      setActiveStep(-1);
    }
  };

  /** Close all previous events if new ones are added/deleted */
  const [numberOfDocs, setNumberOfDocs] = React.useState<number>(0);

  React.useEffect(() => {
    setNumberOfDocs(isGroup ? combinedEvents.length : formData.document?.[name]?.length);
  }, [formData.document, name]);

  React.useEffect(() => {
    activateStep(separateLatest || allClosed ? -1 : 0)();
  }, [numberOfDocs]);

  const setEditing =
    (i: number, t?: string) =>
    (e: React.MouseEvent): void => {
      startEditExtraAction?.();
      e.stopPropagation();
      setEditingEvent(i);
      activateStep(i)();
      setEditingIndex(i);
      if (isGroup && t) setEditingEventType(t);
    };

  /** editingEvent is true = adding new event */
  const addingNewEvent = editingEvent === true;
  /** editingEvent is an index = editing an existing event */
  const editing = !!(editingEvent || editingEvent === 0);

  /** Combined events of different types sorted for reference order */
  const combinedEventsSorted = combinedEventsToSorted(combinedEvents, propsToUse);

  /** Reference order for sorting combined events, update only after saving, canceling etc. */
  const combinedEventsSortedRef = React.useRef<any[]>(combinedEventsSorted);
  const combinedEventsSortedRefIds = combinedEventsSortedRef.current.map((e) => e.id);

  const _thisEvents = isGroup
    ? // Sort combined events here based on reference order
      combinedEvents.toSorted(
        (a, b) => combinedEventsSortedRefIds.indexOf(a.id) - combinedEventsSortedRefIds.indexOf(b.id),
      )
    : Array.isArray(formData.document?.[name])
      ? formData.document?.[name]
      : [];

  /** Temporary editing data to keep events in initial order while editing, this allows sorting actual form data in the background */
  const editingEvents = React.useRef<typeof _thisEvents | undefined>(undefined);
  /** Helper index used to locate current event if events are sorted while editing */
  const [editingIndex, setEditingIndex] = React.useState<number | undefined>(undefined);

  /** Set and unset temporary editing data */
  React.useEffect(() => {
    if (editing && !editingEvents.current) {
      editingEvents.current = structuredClone(_thisEvents);
    } else {
      editingEvents.current = undefined;
    }
  }, [editing]);

  /** Pick current data based on editing status */
  const thisEvents = structuredClone(editingEvents.current ? editingEvents.current : _thisEvents);

  /** Add new event */
  const addNew = (n?: string) =>
    propsToUse(n).addNewOverrideAction
      ? () => {
          setEditingEventType(n);
          propsToUse(n).addNewOverrideAction?.();
        }
      : () => {
          addNewExtraAction?.();
          setEditingEventType(n);
          createEvent(
            n ? n : name,
            propsToUse(n).formData,
            propsToUse(n).mutators?.creationMutator,
            propsToUse(n).defaultValues,
          )();
        };

  /** Cancel adding new event */
  const addNewCancel =
    (i = 0, n?: string) =>
    (): void => {
      cancelExtraAction?.();
      deleteEvent(i, n ?? name, propsToUse(n).formData)();
      setEditingEvent(false);
      setEditingEventType(undefined);

      // Update reference order for combined events
      if (isGroup) combinedEventsSortedRef.current = combinedEventsSorted;
    };

  /** Save changes done to an event */
  const save = (n?: string) => {
    const typeProps = propsToUse(n);

    // Briefly equalize temporary editing data with actual form data - temporary data is set to undefined when editing ends
    editingEvents.current = _thisEvents;
    saveExtraAction?.();

    sortByDateAndSave(n ?? name, typeProps.formData, typeProps.dateFieldName);

    setEditingEvent(false);
    setEditingEventType(undefined);

    // Update reference order for combined events
    if (isGroup) combinedEventsSortedRef.current = combinedEventsSorted;
  };

  /** Index for event deletion */
  const [deleteIndex, setDeleteIndex] = React.useState<number | null>(null);

  const openDeleteDialog =
    (i: number, t?: string) =>
    (e: React.MouseEvent): void => {
      e.stopPropagation();
      if (isGroup && t) setEditingEventType(t);
      setDeleteIndex(i);
      setAnchor(null);
    };

  const deleteCancelCallback = (): void => {
    setDeleteIndex(null);
    setEditingEventType(undefined);
  };

  const deleteConfirmCallback = (deleteProps?: TDeleteProps): void => {
    if (deleteIndex !== null && deleteIndex !== undefined) {
      const currentProps = propsToUse(editingEventType);

      if (isGroup && editingEventType) {
        const i = currentProps.formData.document[editingEventType]?.findIndex(
          (e: any) => thisEvents[deleteIndex].id === e.id,
        );
        currentProps.deleteExtraAction?.(i, currentProps.formData)();
        deleteEvent(i, editingEventType, currentProps.formData, deleteProps)();
      } else {
        deleteExtraAction?.(deleteIndex, formData)();
        deleteEvent(deleteIndex, name, formData, deleteProps)();
      }

      setDeleteIndex(null);
      setEditingEventType(undefined);
      if (isGroup) combinedEventsSortedRef.current = combinedEventsSorted;
    }
  };

  // Prevent render until length of event array has been updated
  React.useEffect(() => {
    // Prevent previous event opening if a new event is canceled outside EventStepper
    if (thisEvents.length < (thisEventsLength ?? 0)) {
      setEditingEvent(false);
    }
    setThisEventsLength(thisEvents.length);
  }, [thisEvents.length]);

  /** Track events length inside EventStepper to prevent forms from reacting immediately to changes */
  const [eventsLength, setEventsLength] = React.useState<number>(thisEvents?.length || 0);

  React.useEffect(() => {
    if (thisEvents?.length > (eventsLength || 0)) {
      // Set editing new event if the events length has increased, meaning a new event has been added
      setEditingEvent(true);
    }

    setEventsLength(thisEvents?.length + readOnlyEvents.length);
  }, [thisEvents?.length, readOnlyEvents.length]);

  const contextValues = {
    isGroup,
    thisEvents,
    _thisEvents,
    thisEventsLength,
    anchor,
    editingEvent,
    editingEventType,
    editingEvents,
    editingIndex,
    setEditingIndex,
    setEditing,
    save,
    activeStep,
    activateStep,
    toggleMenu,
    openDeleteDialog,
    deleteIndex,
    setDeleteIndex,
    deleteConfirmCallback,
    deleteCancelCallback,
    activeView,
    setActiveView,
  };

  // Render conditions

  const showSomeAddNewElement = (n?: string) => Boolean(!addingNewEvent && !propsToUse(n).hideNewButton);

  const showButtonDisabledElement = (n?: string) =>
    Boolean(propsToUse(n).buttonDisabled && React.isValidElement(propsToUse(n).buttonDisabledElement));

  const showNoPreviousEventsText = () => Boolean(viewing && !eventsLength && noPreviousEventsTextHeader);

  const showPreviousEvents = () =>
    Boolean((eventsLength === 1 && addingNewEvent === false) || eventsLength > 1 || readOnlyEvents.length > 0);

  const showSeparateLatestEvent = () => Boolean(thisEvents?.length > 0 && editingEvent !== true && separateLatest);

  //

  return (
    <EventStepperContext.Provider value={contextValues}>
      <FormRow title={addNewTextHeader} condition={!viewing}>
        <React.Fragment>
          {/** Stepper extra element */}
          {React.isValidElement(stepperExtraElement)
            ? stepperExtraElement
            : typeof stepperExtraElement === 'function'
              ? stepperExtraElement(editingIndex ?? 0)
              : undefined}
          {(isGroup ? types : [undefined]).map((n, i) => {
            const someNewButtonHidden = types.map((t) => propsToUse(t).hideNewButton);

            return (
              showSomeAddNewElement(n) &&
              (showButtonDisabledElement(n) ? (
                /** Button disabled element */
                <div
                  key={'element' + i}
                  style={{
                    marginBottom: isGroup && types.length > 1 && i < types.length - 1 ? '2.5rem' : undefined,
                  }}
                >
                  {propsToUse(n).buttonDisabledElement}
                </div>
              ) : (
                /** "Add new" button */
                <ToolTip
                  key={'tooltip' + i}
                  title={propsToUse(n).buttonDisabledMessage}
                  content={
                    <React.Fragment>
                      <div
                        style={{
                          width: 'min-content',
                          marginBottom:
                            i < types.length - 1 && !someNewButtonHidden.some((bh) => bh) ? '4.5rem' : undefined,
                        }}
                      >
                        <ActionButton
                          text={propsToUse(n).addNewTextButton}
                          onClick={addNew(n)}
                          width={20}
                          height={5}
                          fontSize={18}
                          disabled={propsToUse(n).buttonDisabled}
                        />
                      </div>
                    </React.Fragment>
                  }
                  hover={propsToUse(n).buttonDisabled}
                  placement="top-start"
                  arrow={false}
                />
              ))
            );
          })}
          {/** EditingEvent component for new event */}
          {addingNewEvent ? (
            <div style={{ marginTop: '2rem' }}>
              <EditingEvent
                thisIndex={editingIndex ?? 0}
                editingElements={propsToUse(editingEventType).editingElements}
                thisOnChange={onChangeEvent(
                  editingIndex ?? 0,
                  editingEventType ?? name,
                  propsToUse(editingEventType).dateFieldName,
                  propsToUse(editingEventType).formData,
                  editingEvents.current,
                  setEditingIndex,
                  propsToUse(editingEventType).mutators?.onChangeMutator,
                )}
                cancel={addNewCancel(undefined, editingEventType)}
                save={() => save(editingEventType)}
                saveDisabled={propsToUse(editingEventType).saveDisabled}
                saveDisabledMessage={propsToUse(editingEventType).saveDisabledMessage}
              />
            </div>
          ) : null}
        </React.Fragment>
      </FormRow>
      {/** Show a "No previous events" text in viewing mode if there are no events recorded */}
      {showNoPreviousEventsText() && (
        <FormRow title={previousEventsTextHeader}>{fm(noPreviousEventsTextHeader!)}</FormRow>
      )}
      {/** Previous events */}
      {showPreviousEvents() ? (
        <React.Fragment>
          {/** Separate latest event view */}
          {showSeparateLatestEvent() && (
            <StandAloneView normalizedProps={normalizedProps} propsByType={propsByType} types={types} />
          )}
          {/** View selector (if tableContent exists) */}
          {tableContent && <ViewSelector normalizedProps={normalizedProps} propsByType={propsByType} types={types} />}
          {/** Event view(s) */}
          {
            {
              list: <ListView normalizedProps={normalizedProps} propsByType={propsByType} types={types} />,
              table: <TableView normalizedProps={normalizedProps} propsByType={propsByType} types={types} />,
            }[activeView]
          }
        </React.Fragment>
      ) : undefined}
    </EventStepperContext.Provider>
  );
};

const EventStepper = (props: IEventStepper): React.JSX.Element => <EventStepperComponent type="single" {...props} />;

export default EventStepper;
