/** Collection of styled elements / containers for use in history pages */

import { styled } from '@mui/material';
import { Container, Item } from 'Components/Grid';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

export const HistoryEventListTopHeader = styled(Container)(({ theme }) => ({
  height: '4rem',
  borderBottom: `0.1rem solid ${theme.palette.grey[500]}`,
}));

export const HistoryEventListItemStyled = styled(Container)(({ theme }) => ({
  paddingLeft: '1rem',
  marginTop: '0.5rem',
  '* > &:first-of-type': { marginTop: '0.7rem' }, // Add a little margin if theres no top header
  '* > &:not(:last-of-type)': {
    paddingBottom: '0.5rem',
    borderBottom: `0.1rem solid ${theme.palette.grey[500]}`,
  },
}));

export const HistoryEventListTitle = styled(Item)(({ theme }) => ({
  color: theme.palette.grey[700],
}));

export const HistoryEventListItem = ({
  title,
  value,
  titleWidth = 4,
  fullWidthValue,
}: {
  title?: string | React.JSX.Element;
  value?: string | React.JSX.Element | null;
  titleWidth?: number;
  fullWidthValue?: boolean;
}) => (
  <HistoryEventListItemStyled>
    {title && (
      <HistoryEventListTitle xs={titleWidth}>
        {typeof title === 'string' ? <FormattedMessage id={title} /> : title}
      </HistoryEventListTitle>
    )}
    <Item style={{ whiteSpace: 'pre-line' }} xs={fullWidthValue ? 12 : true}>
      {value ?? '-'}
    </Item>
  </HistoryEventListItemStyled>
);
