import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';

import colors from '../../config/theme/colors';
import { styleDocument } from '../../config/theme/componentTheme';
import { Container, Item } from 'Components/Grid';

const StyledDiv = styled('div')({
  margin: `0 -${styleDocument.padding}`,
  padding: `3rem ${styleDocument.padding}`,
  borderTop: '1px solid #c8c8c8',
});

const StyledHeader = styled('div')({
  color: colors.primary,
  fontSize: '2rem',
  fontWeight: 600,
  marginBottom: '1rem',
});

const FormSection = ({
  header,
  condition = true,
  headerRef = undefined,
  children,
  style,
  headerFormat = true,
  headerControls,
}: IOwnProps): React.JSX.Element | null =>
  condition ? (
    <StyledDiv ref={headerRef} style={style}>
      {typeof header === 'string' && header.length > 0 && (
        <Container justifyContent="space-between">
          <Item>
            <StyledHeader>{headerFormat ? <FormattedMessage id={header} /> : header}</StyledHeader>
          </Item>
          {React.isValidElement(headerControls) && <Item>{headerControls}</Item>}
        </Container>
      )}
      {children}
    </StyledDiv>
  ) : null;

interface IOwnProps {
  header?: string;
  children: React.JSX.Element | Array<React.JSX.Element | undefined | false> | string | undefined | false;
  condition?: boolean;
  headerRef?: React.RefObject<HTMLDivElement>;
  style?: React.CSSProperties;
  headerFormat?: boolean;
  headerControls?: React.JSX.Element;
}

export default FormSection;
