import { Dialog, DialogActions, DialogTitle, Paper, PaperProps } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';

import ActionButton from '../ActionButton';

import colors from '../../config/theme/colors';
import { Container, Item } from '../Grid';

const DialogPaper = (props: PaperProps): React.JSX.Element => <Paper square={true} {...props} />;

const StyledCancel = styled('a')({
  fontSize: '1.6rem',
  fontWeight: 600,
  cursor: 'pointer',
  color: colors.primary,
  textTransform: 'uppercase',
  width: 'auto',
});

const ConfirmationDialog = ({
  open,
  text,
  cancel,
  confirm,
  fullWidth = true,
  width = 'xs',
  onlyConfirm = false,
  centeredText = false,
}: IConfirmationDialog): React.JSX.Element => (
  <Dialog open={open} maxWidth={fullWidth ? width : undefined} fullWidth={fullWidth} PaperComponent={DialogPaper}>
    <DialogTitle style={{ padding: '3rem' }}>
      <Container alignItems="center" justifyContent={centeredText ? 'center' : 'flex-start'}>
        <Item style={{ color: colors.tertiaryText }}>{text}</Item>
      </Container>
    </DialogTitle>
    <DialogActions style={{ padding: '0rem 3rem 3rem 3rem' }}>
      <Container alignItems="center">
        {!onlyConfirm && (
          <Item xs={6}>
            <Container justifyContent="center">
              <Item>
                <StyledCancel onClick={cancel?.callback || undefined}>
                  {cancel?.text || <FormattedMessage id="general.cancel" />}
                </StyledCancel>
              </Item>
            </Container>
          </Item>
        )}
        <Item xs={onlyConfirm ? 12 : 6}>
          <Container justifyContent="center">
            <Item>
              <ActionButton
                text={confirm.text || 'general.accept'}
                onClick={confirm.callback || undefined}
                width={15}
                height={4}
                fontSize={16}
                loading={confirm.loading}
                disabled={confirm.disabled}
              />
            </Item>
          </Container>
        </Item>
      </Container>
    </DialogActions>
  </Dialog>
);

export interface IConfirmationDialog {
  open: boolean;
  text: string | React.JSX.Element;
  cancel?: {
    callback: (() => void) | ((e: React.MouseEvent<Element, MouseEvent>) => void) | null;
    text?: string | React.JSX.Element; // Locale element or any string
  };
  confirm: {
    callback: (() => void) | ((e: React.MouseEvent<Element, MouseEvent>) => void) | null;
    text?: string; // Locale string
    loading?: boolean;
    disabled?: boolean;
  };
  width?: 'xs' | 'sm' | 'md' | 'lg';
  onlyConfirm?: boolean;
  centeredText?: boolean;
  fullWidth?: boolean;
}

export default ConfirmationDialog;
