import { equals, indexOf, without } from 'ramda';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { styled } from '@mui/system';

import ActionButton from '../ActionButton';
import ContentWrapper from '../ContentWrapper';
import NavigationMenu from './NavigationMenu';
import dashIcon from './to-summary-icon.svg';

import colors from '../../config/theme/colors';
import { screenBreakpoints, styleToolbar } from '../../config/theme/componentTheme';
import { getNavigableRoutes, getSectionTitle } from '../../utility/randomUtil';
import { useAppSelector as useSelector } from 'Store/index';
import { filteredDocumentTypes } from '../../config/generalSettings';
import { Container, Item } from 'Components/Grid';
import { ICapabilityContextProps, withCapabilities } from 'Containers/CapabilityHandler';
import requiredFields from '../../config/requiredFields.json';
import { checkMyServCapability, isMyServiceAvailable } from 'Routes/MyService/util';

const isSafari = window['safari' as keyof typeof window] !== undefined;

const StyledToolbar = styled('div')({
  width: '100%',
  height: styleToolbar.height,
  background: styleToolbar.backgroundColor,
  color: '#fff',
  fontSize: '1.8rem',
  fontWeight: 'normal',
  position: isSafari ? '-webkit-sticky' : 'sticky',
  top: 0,
  zIndex: 5,
});

const StyledRowContainer = styled(Container)({
  height: '100%',
});

const StyledRowItem = styled(Item)({});

const DashIcon = styled(({ ...other }) => <img src={dashIcon} {...other} />)({
  display: 'block',
});

const ArrowStyle = styled('div')({
  color: colors.tertiary,
  fontSize: '1rem',
  width: '5rem',
});

const SectionText = styled('div')({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  [`@media only screen and (max-width: ${screenBreakpoints.xl}px)`]: {
    maxWidth: '50rem !important',
  },
  [`@media only screen and (max-width: ${screenBreakpoints.lg}px)`]: {
    maxWidth: '45rem !important',
  },
  [`@media only screen and (max-width: ${screenBreakpoints.md}px)`]: {
    maxWidth: '20rem !important',
  },
});

/**
 * Check formdata for required fields and return an array of missing fields / fields with invalid value
 * @param {string} type - Document type
 * @param {TAnyObject} formData - Form data
 * @returns {Array<{ name: string; locale: string }>} Array of missing required fields and their locale id:s
 */
const getUnteredRequiredFields = (type: string, formData: TAnyObject): Array<{ name: string; locale: string }> => {
  const thisFormRequiredFields = requiredFields[type];
  if (!thisFormRequiredFields) return [];

  const unenteredFields: Array<{ name: string; locale: string }> = [];
  const fields = thisFormRequiredFields['required'];
  fields.forEach((requiredField: IRequiredField) => {
    // Note: would probably need a recursive approach for data that is more nested
    const enteredValueIsAccepted = (): boolean => {
      let isAccepted = true;

      let acceptedValueType = requiredField['acceptedValueType'];
      let enteredValue = formData[requiredField.field];

      const isNotTypeOf = (accepted: typeof acceptedValueType, entered: typeof enteredValue): boolean =>
        typeof entered !== accepted ||
        (typeof entered === 'string' && entered.length === 0) ||
        (typeof entered === 'object' && (entered === null || (Array.isArray(entered) && entered.length === 0)));

      // Extract possible array type
      if (Array.isArray(acceptedValueType) && acceptedValueType.length > 0) {
        if (!Array.isArray(enteredValue) || enteredValue.length === 0) return false;
        acceptedValueType = acceptedValueType[0];
        enteredValue = enteredValue[0];
      }
      // If acceptedValueType is in the form of a "schema" (object)
      if (
        acceptedValueType &&
        typeof acceptedValueType === 'object' &&
        enteredValue &&
        typeof enteredValue === 'object'
      ) {
        const a = acceptedValueType as { [key: string]: any };
        const e = enteredValue as { [key: string]: any };
        Object.keys(acceptedValueType).forEach((key) => {
          const k = key as keyof typeof acceptedValueType;
          // An array in the schema is handled as a tuple
          if (Array.isArray(a[k])) {
            if (!Array.isArray(e[k]) || a[k].length !== e[k].length) isAccepted = false;
            a[k].forEach((ak: 'string' | 'number' | 'object', i: number) => {
              if (ak) {
                if (isNotTypeOf(ak, e[k] ? e[k][i] : null)) isAccepted = false;
              }
            });
            // If schema value is a "typeof" type
          } else {
            if (isNotTypeOf(a[k], e[k])) isAccepted = false;
          }
        });
      }
      // If acceptedValueType is a "typeof" type
      else if (isNotTypeOf(acceptedValueType, enteredValue)) {
        isAccepted = false;
      }
      return isAccepted;
    };

    if (!enteredValueIsAccepted()) {
      if (requiredField.conditions) {
        const conditionsMet = requiredField.conditions.every(
          (cond: { field: string; value: { hasValue?: Array<any>; hasNotValue?: Array<any> } }) => {
            const conditionFieldValue = formData[cond.field];
            const hasValueCondition =
              cond.value?.hasValue?.includes(conditionFieldValue) ||
              cond.value?.hasValue?.some((value) => equals(value, conditionFieldValue));

            const hasNotValueCondition = cond.value.hasNotValue
              ? !cond.value?.hasNotValue?.includes(conditionFieldValue) ||
                !cond.value?.hasNotValue?.some((value) => equals(value, conditionFieldValue))
              : false;
            return hasValueCondition || hasNotValueCondition;
          },
        );
        if (!conditionsMet) return;
      }
      unenteredFields.push({
        name: requiredField['field'] ?? '',
        locale: requiredField['locale'] ?? requiredField['field'] ?? '',
      });
    }
  });
  return unenteredFields;
};

type TAcceptedValue = 'string' | 'number' | 'object' | { [key: string]: any };

interface IRequiredField {
  field: string;
  acceptedValues: any | null; // Not used yet
  acceptedValueType: TAcceptedValue | Array<TAcceptedValue>;
  conditions?: Array<{ field: string; value: { hasValue?: Array<any>; hasNotValue?: Array<any> } }>;
  locale?: string;
}

const Toolbar = ({ current, type = 'normal', capabilityGroups = {}, ...other }: IOwnProps): React.JSX.Element => {
  const platform = useSelector((s: IState) => s.session.platforms?.selected);
  const documents = useSelector((s: IState) => s.documents.sortedAndMergedDocuments);
  const filteredSections = filteredDocumentTypes(platform, documents);
  const formState = useSelector((s: IState) => s.form);
  const session = useSelector((s: IState) => s.session);

  const otherProps = type === 'normal' ? (other as INormalToolbar) : undefined;

  const formDataDocument = otherProps?.editing ? (formState.formData[otherProps?.editing] as TAnyObject) : null;
  const editingDocumentType = otherProps?.editing
    ? formState.editingDocuments.find((ed) => ed.id === otherProps?.editing)?.name
    : null;

  const myServCapability = platform ? checkMyServCapability(platform, capabilityGroups) : false;

  const myServiceSectionAvailable = platform && isMyServiceAvailable(platform, myServCapability, documents);

  if (!myServiceSectionAvailable) {
    filteredSections.push('myService');
  }

  const saveClick = (e: React.MouseEvent): void => {
    if (!otherProps) return;
    otherProps.editing && otherProps.saveDraft(otherProps.editing)(e);
    otherProps.saveButtonCallback && otherProps.saveButtonCallback();
  };

  const cancelClick = (e: React.MouseEvent): void => {
    if (!otherProps || !otherProps.editing) return;
    otherProps.cancelDraft(otherProps.editing)(e);
    otherProps.cancelButtonCallback && otherProps.cancelButtonCallback();
  };

  const enabledNames = React.useRef(
    without(
      filteredSections,
      getNavigableRoutes(session).map((d: { name: string }) => d.name),
    ),
  );

  React.useEffect(() => {
    enabledNames.current = without(
      filteredSections,
      getNavigableRoutes(session).map((d: { name: string }) => d.name),
    );
  }, [session, filteredSections]);

  const nextSection = (name: string): string =>
    indexOf(name, enabledNames.current) < enabledNames.current.length - 1
      ? enabledNames.current[indexOf(name, enabledNames.current) + 1]
      : enabledNames.current[0];

  const section = `${current}.${getSectionTitle(current, platform)}`;

  const editingOrViewing = otherProps && (otherProps.editing || otherProps?.view?.viewing);

  const unenteredRequiredFields =
    editingDocumentType && formDataDocument ? getUnteredRequiredFields(editingDocumentType, formDataDocument) : [];

  const dropDownHidden = (otherProps?.hiddenElements || []).includes('dropDown');
  const saveButtonHidden = (otherProps?.hiddenElements || []).includes('saveButton');
  const nextSectionHidden = (otherProps?.hiddenElements || []).includes('nextSection');

  return (
    <StyledToolbar>
      <ContentWrapper>
        <StyledRowContainer alignItems="center">
          <StyledRowItem xs={6} md={8} lg={9}>
            <StyledRowContainer alignItems="center">
              <StyledRowItem>
                <Link to="/">
                  <DashIcon />
                </Link>
              </StyledRowItem>
              <StyledRowItem>
                <ArrowStyle style={{ textAlign: 'center' }}>❯</ArrowStyle>
              </StyledRowItem>
              <StyledRowItem xs={9} md={10}>
                {!editingOrViewing && !dropDownHidden && (
                  <NavigationMenu current={current} names={enabledNames.current} platform={platform} />
                )}
                {!editingOrViewing && dropDownHidden && (
                  <SectionText>
                    <FormattedMessage id={section} />
                  </SectionText>
                )}
                {editingOrViewing && (
                  <StyledRowContainer alignItems="center">
                    <StyledRowItem>
                      <SectionText>
                        <FormattedMessage id={section} />
                      </SectionText>
                    </StyledRowItem>
                    <StyledRowItem>
                      <ArrowStyle style={{ textAlign: 'center' }}>❯</ArrowStyle>
                    </StyledRowItem>
                    <StyledRowItem>
                      <FormattedMessage id={'general.edit'} />
                    </StyledRowItem>
                  </StyledRowContainer>
                )}
              </StyledRowItem>
            </StyledRowContainer>
          </StyledRowItem>
          <StyledRowItem xs={6} md={4} lg={3}>
            {otherProps?.editing && (
              <StyledRowContainer alignItems="center" justifyContent="flex-end">
                <StyledRowItem style={{ marginRight: !saveButtonHidden ? '1rem' : undefined }}>
                  {!otherProps.cancelButtonDisabled && (
                    <ActionButton
                      text={otherProps.customLocales?.cancel?.title || 'general.cancel'}
                      onClick={cancelClick}
                      width={13}
                      height={3.5}
                      fontSize={16}
                      fontWeight={400}
                      alternate={true}
                      border={false}
                      loading={otherProps.spinnerCancelButton}
                      disabled={otherProps.spinnerEnabled}
                      disabledTooltip={
                        otherProps.customLocales?.cancel?.toolTip === null
                          ? undefined
                          : otherProps.customLocales?.cancel?.toolTip || <FormattedMessage id="general.cancelInfo" />
                      }
                      tooltip={
                        otherProps.customLocales?.cancel?.toolTip === null
                          ? undefined
                          : otherProps.customLocales?.cancel?.toolTip || <FormattedMessage id="general.cancelInfo" />
                      }
                    />
                  )}
                </StyledRowItem>
                {!saveButtonHidden && (
                  <StyledRowItem>
                    <ActionButton
                      text={
                        otherProps.customLocales?.save?.title ||
                        (otherProps.editing === 'invite' ? 'myService.send' : 'general.save')
                      }
                      onClick={saveClick}
                      width={13}
                      height={3.5}
                      fontSize={16}
                      alternate={true}
                      disabled={otherProps.saveButtonDisabled || unenteredRequiredFields.length > 0 ? true : false}
                      disabledTooltip={
                        otherProps.saveButtonDisabledTooltip ? (
                          otherProps.saveButtonDisabledTooltip
                        ) : unenteredRequiredFields.length > 0 ? (
                          <>
                            <FormattedMessage id="general.requiredFields" />
                            <span>:</span>
                            <ul>
                              {unenteredRequiredFields.map((field) => (
                                <li key={field.name}>
                                  <FormattedMessage id={field.locale} />
                                </li>
                              ))}
                            </ul>
                          </>
                        ) : undefined
                      }
                      loading={otherProps.spinnerEnabled}
                      tooltip={otherProps.customLocales?.save?.toolTip || undefined}
                    />
                  </StyledRowItem>
                )}
              </StyledRowContainer>
            )}
            {otherProps?.view?.viewing && (
              <StyledRowContainer alignItems="center" justifyContent="flex-end">
                <StyledRowItem>
                  <ActionButton
                    text="general.endView"
                    onClick={otherProps.view.endView(otherProps.view.viewing)}
                    width={17}
                    height={3.5}
                    fontSize={16}
                    alternate={true}
                  />
                </StyledRowItem>
              </StyledRowContainer>
            )}
            {!editingOrViewing && !nextSectionHidden && (
              <Link to={'/' + nextSection(current)}>
                <StyledRowContainer alignItems="center" style={{ textAlign: 'right' }}>
                  <StyledRowItem xs={true} style={{ color: colors.tertiary }}>
                    <FormattedMessage id="general.nextSection" />
                  </StyledRowItem>
                  <StyledRowItem>
                    <ArrowStyle style={{ textAlign: 'right', width: '2.5rem' }}>❯</ArrowStyle>
                  </StyledRowItem>
                </StyledRowContainer>
              </Link>
            )}
          </StyledRowItem>
        </StyledRowContainer>
      </ContentWrapper>
    </StyledToolbar>
  );
};

interface IStandAloneToolbar {
  type?: 'stand-alone';
}
export interface IAdditionalProps {
  cancelButtonCallback?: () => void;
  saveButtonCallback?: () => void;
  saveButtonDisabled?: boolean;
  saveButtonDisabledTooltip?: React.JSX.Element;
  cancelButtonDisabled?: boolean;
  spinnerEnabled?: boolean;
  spinnerCancelButton?: boolean;
}

interface INormalToolbar extends IAdditionalProps {
  type?: 'normal';
  editing?: string;
  saveDraft: (id: string) => (event: React.MouseEvent) => void;
  cancelDraft: (id: string) => (event: React.MouseEvent) => void;
  view?: {
    viewing: string;
    endView: (id: string) => (event: React.MouseEvent) => void;
  };
  customLocales?: {
    cancel?: { title?: string; toolTip?: React.JSX.Element | null }; // Null for no tooltip
    save?: { title?: string; toolTip?: React.JSX.Element | null };
  };
  hiddenElements: Array<'saveButton' | 'nextSection' | 'dropDown'>;
}

interface IToolbarBasics extends ICapabilityContextProps {
  current: string;
  type?: 'normal' | 'stand-alone';
}

type IOwnProps = (IToolbarBasics & INormalToolbar) | (IToolbarBasics & IStandAloneToolbar);

export default withCapabilities(Toolbar);
