import * as React from 'react';
import { styled } from '@mui/system';
import colors from '../../../../../../config/theme/colors';
import { useTabulationTools } from 'Utility/customHooks';

const StyledTableRow = styled('div', {
  shouldForwardProp: (prop) => prop !== 'compact' && prop !== 'isHistory',
})(({ compact, isHistory }: { compact?: boolean; isHistory?: boolean }) => ({
  display: 'grid',
  gridTemplateColumns: '3fr 2fr',
  padding: isHistory ? `0.25rem 0 0.25rem 0` : `0 0 ${compact ? 0.5 : 1}rem 0`,
}));

const StyledTableItem = styled('div')({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const StyledTableItemTitle = styled(StyledTableItem)({
  fontWeight: 600,
  color: colors.darkGray,
});

const StyledTableItemDescription = styled('div')({
  fontSize: '14px',
});

/**
 * A resizeable table with columns etc.
 * @prop {Array<TChild>} children - Table rows as objects. The prop 'title' is the first column and the 'content' object/array forms the remaining columns. Adjust column width with the 'xs' prop. They default to 3 for title, 2 for content.
 * @prop {Partial<TChild>} titleRow - Topmost row, styled as bolded gray text. Used as column headers or similar. Omit 'title' to leave the title column empty.
 * @prop {number} width - Table width. If given as number, converts to percentage, else used as is.
 * @prop {boolean} bottomMargin - Enable/disable bottom margin.
 */
const TableContent = ({
  titleRow,
  children,
  width,
  isHistory = false,
  bottomMargin = true,
  disableTitleRowBorder = false,
  compact = false,
  currentIdStart = '10',
}: ITableContent): React.JSX.Element => {
  const gridTemplateColumns = (title: TChild['title'], content: TChild['content']) => {
    let columns = `${title.xs ?? 3}fr`;
    const contentArray = Array.isArray(content) ? content : [content];
    contentArray.forEach((c) => (c.xs ? (columns += ` ${c.xs}fr`) : (columns += ' 2fr')));
    return columns;
  };

  const { currentId, inputRefs, onKeyDown, subIds } = useTabulationTools({
    currentIdStart,
    childrenLength: children.length,
  });

  return (
    <div
      onKeyDown={onKeyDown}
      style={{
        width: typeof width === 'number' ? `${width}%` : typeof width === 'string' ? width : '50.22%',
        marginBottom: isHistory ? undefined : bottomMargin ? '3.5rem' : undefined,
      }}
    >
      <StyledTableRow
        style={{
          borderBottom: disableTitleRowBorder ? undefined : `1px solid ${colors.darkGray}`,
          gridTemplateColumns: titleRow
            ? gridTemplateColumns(
                titleRow.title ? titleRow.title : children[0].title,
                titleRow.content ? titleRow.content : children[0].content,
              )
            : gridTemplateColumns(children[0].title, children[0].content),
          ...(titleRow?.style ?? {}),
        }}
        compact={compact}
        isHistory={isHistory}
      >
        {titleRow && (
          <React.Fragment>
            <StyledTableItemTitle
              style={{
                paddingRight: isHistory ? undefined : '1.5rem',
                paddingLeft: isHistory ? '0.5rem' : undefined,
                ...(titleRow.title?.style ?? {}),
              }}
            >
              {titleRow.title && titleRow.title.content}
            </StyledTableItemTitle>
            {(Array.isArray(titleRow.content) ? titleRow.content : [titleRow.content]).map((c, i) => (
              <StyledTableItemTitle
                key={'title' + i}
                style={{ paddingRight: isHistory ? undefined : '1.5rem', ...(c?.style ?? {}) }}
              >
                {typeof c?.content === 'function'
                  ? c?.content(
                      ['', ...subIds].some((l) => `${0}${i}${l}` === currentId) ? 0 : -1,
                      inputRefs,
                      `${0}${i}`,
                    )
                  : c?.content
                    ? c.content
                    : ''}
              </StyledTableItemTitle>
            ))}
          </React.Fragment>
        )}
      </StyledTableRow>
      {children.map((child: (typeof children)[0], index: number) => (
        <StyledTableRow
          key={index}
          style={{
            backgroundColor:
              !child.title.isInfo && (isHistory ? index % 2 === 0 : index % 2 !== 0) ? colors.lightestGray : undefined,
            paddingTop: isHistory ? undefined : index === 0 ? '1.4rem' : '0.5rem',
            gridTemplateColumns: gridTemplateColumns(child.title, child.content),
            ...(child.style ?? {}),
          }}
          compact={compact}
          isHistory={isHistory}
        >
          <StyledTableItem
            style={{
              paddingLeft: isHistory ? '0.5rem' : '1.5rem',
              color: colors.tertiaryText,
              fontStyle: child.title.isInfo ? 'italic' : 'normal',
              fontSize: child.title.isInfo ? '1.4rem' : '1.6rem',
              ...(child.title.style ?? {}),
            }}
          >
            {child.title.content ?? ''}
            {child.title.description && (
              <StyledTableItemDescription>{child.title.description}</StyledTableItemDescription>
            )}
          </StyledTableItem>
          {(Array.isArray(child.content) ? child.content : [child.content]).map((c, i, arr) => (
            <StyledTableItem
              key={'content' + i}
              style={{
                paddingRight: isHistory ? (i === arr.length - 1 ? undefined : '1.5rem') : '1.5rem',
                ...(c.style ?? {}),
              }}
            >
              {typeof c.content === 'function'
                ? c.content(
                    ['', ...subIds].some((l) => `${index + 1}${i}${l}` === currentId) ? 0 : -1,
                    inputRefs,
                    `${index + 1}${i}`,
                  )
                : c.content}
            </StyledTableItem>
          ))}
        </StyledTableRow>
      ))}
      <StyledTableRow />
    </div>
  );
};

type TChild = {
  style?: { [key: string]: string | number };
  title: {
    content: string;
    description?: string;
    xs?: number;
    isInfo?: boolean;
    style?: { [key: string]: string | number | undefined };
  };
  content: Array<{
    content:
      | string
      | React.JSX.Element
      | ((
          tabIndex: TTabulationTools['tabIndex'],
          inputRefs: TTabulationTools['inputRefs'],
          id: TTabulationTools['identifier'],
        ) => string | React.JSX.Element);
    xs?: number;
    style?: { [key: string]: string | number | undefined };
  }>;
};

interface ITableContent {
  children: Array<TChild>;
  titleRow?: Partial<TChild>;
  width?: string | number;
  isHistory?: boolean;
  bottomMargin?: boolean;
  disableTitleRowBorder?: boolean;
  compact?: boolean;
  /**
   * Tabulation tools starts indexing from an element matching this identifier
   * Format: row, column and optional sub element
   * Example: '000'
   */
  currentIdStart?: string;
}

export default TableContent;
