/**
 * Create a blue subheader for history rows
 */
import * as React from 'react';

import colors from '../../config/theme/colors';
import { styled } from '@mui/system';

interface StyledProps {
  size: string;
}

const StyledSubHeader = styled('div', { shouldForwardProp: (prop) => prop !== 'size' })(({ size }: StyledProps) => ({
  fontSize: `${size === 'small' ? '1.6' : '2.2'}rem`,
  color: colors.primary,
  fontWeight: 600,
  margin: '3rem 0 1rem 0',
  '* > &:first-of-type': {
    margin: '0 0 1rem 0',
  },
}));

const HistoryRowSubHeader = ({ header, fontSize = 'large' }: IOwnProps): React.JSX.Element => (
  <StyledSubHeader size={fontSize}>{header}</StyledSubHeader>
);

interface IOwnProps {
  header: React.JSX.Element | string | number;
  fontSize?: 'small' | 'large';
}

export default HistoryRowSubHeader;
