import { Paper } from '@mui/material';
import * as React from 'react';

import colors from '../../config/theme/colors';
import ToolTip from '../ToolTip';
import { styled } from '@mui/system';
import { Container, Item } from 'Components/Grid';

const StyledRowContainer = styled(Container)({
  width: '100%',
});

const StyledRowItem = styled(Item)({
  width: '100%',
});

const StyledNumber = styled('div', {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<Partial<IPaperProps>>(({ disabled }: Partial<IPaperProps>) => ({
  width: '3rem',
  height: '3rem',
  backgroundColor: 'white',
  textAlign: 'center',
  lineHeight: '2.6rem',
  border: 'solid 0.5px #cbcbcb',
  borderRadius: '5rem',
  position: 'relative',
  fontSize: '1.6rem',
  color: disabled ? colors.gray : colors.primaryText,
  fontWeight: disabled ? 'normal' : 'bold',
}));

const StyledPaper = styled(Paper, {
  shouldForwardProp: (prop) =>
    !['viewing', 'disabled', 'selected', 'height'].includes(typeof prop === 'string' ? prop : ''),
})(({ viewing, disabled, selected, height }: IPaperProps) => ({
  margin: '-1.5rem 0 0 0',
  padding: '3rem 1rem 1rem 1rem',
  minHeight: height ? `${height}rem` : '15rem',
  cursor: (viewing && viewing === 'true') || (disabled && disabled === true) ? '' : 'pointer',
  fontSize: '1.6rem',
  backgroundColor:
    disabled && selected ? colors.gray : disabled ? colors.white : selected ? colors.primary : colors.white,
  color: disabled && selected ? colors.darkGray : disabled ? colors.gray : selected ? colors.white : colors.primaryText,
  '&:hover': {
    backgroundColor:
      disabled && selected
        ? colors.gray
        : disabled
          ? colors.white
          : selected
            ? colors.primary
            : (viewing && viewing === 'true') || (disabled && disabled === true)
              ? colors.white
              : colors.lightestGray,
  },
}));

interface IPaperProps {
  viewing: string;
  height?: number;
  disabled?: boolean;
  selected?: boolean;
}

const QuestionItem = ({
  qNumber,
  text,
  onClick,
  selected,
  height,
  viewing,
  disabled,
  disabledMessage,
  showValue,
}: IOwnProps): React.JSX.Element => {
  const Card = (
    <div>
      {/* Tooltip requires div as root element */}
      {(typeof qNumber === 'number' || showValue) && (
        <StyledRowContainer justifyContent="center" alignItems="center">
          <StyledRowItem xs={2}>
            <StyledNumber disabled={disabled}>{qNumber}</StyledNumber>
          </StyledRowItem>
        </StyledRowContainer>
      )}
      <StyledRowContainer>
        <StyledRowItem>
          <StyledPaper
            elevation={2}
            selected={selected}
            onClick={!disabled ? onClick : undefined}
            height={height}
            viewing={viewing ? 'true' : 'false'}
            disabled={disabled}
          >
            {text}
          </StyledPaper>
        </StyledRowItem>
      </StyledRowContainer>
    </div>
  );

  return disabled && disabledMessage ? (
    <ToolTip title={disabledMessage} content={Card} hover={true} cursor={'Default'} />
  ) : (
    Card
  );
};

interface IOwnProps {
  qNumber: number | string;
  text: string | React.JSX.Element;
  onClick?: () => void;
  selected?: boolean;
  height?: number;
  viewing?: boolean;
  disabled?: boolean;
  disabledMessage?: React.JSX.Element | string;
  showValue?: true;
}

export default QuestionItem;
