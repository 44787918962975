import * as React from 'react';
import { styled } from '@mui/system';
import { Container, Item } from '../Grid';

const StyledUnit = styled(Item)({
  padding: '0 1rem',
});

const Unit = ({ unit, children, fontWeight = 'normal' }: IOwnProps): React.JSX.Element => (
  <Container alignItems="center" style={{ fontWeight: fontWeight === 'bold' ? 600 : fontWeight }}>
    <Item>{children}</Item>
    {unit && <StyledUnit>{unit}</StyledUnit>}
  </Container>
);

interface IOwnProps {
  unit?: string | number | React.JSX.Element;
  children: string | number | React.JSX.Element;
  fontWeight?: 'normal' | 'bold';
}

export default Unit;
