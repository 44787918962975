import { Paper } from '@mui/material';
import * as React from 'react';
import { styled } from '@mui/system';

import ContentWrapper from '../ContentWrapper';

import { styleDocument } from '../../config/theme/componentTheme';

const StyledPaper = styled(Paper)({
  minHeight: styleDocument.minHeight,
  fontSize: styleDocument.fontSize,
});

const StyledInnerArea = styled('div')({
  padding: styleDocument.padding,
});

const DocumentWrapper = ({ children }: IOwnProps): React.JSX.Element => (
  <ContentWrapper maxWidth="md">
    <StyledPaper elevation={1} square={true}>
      <StyledInnerArea>{children}</StyledInnerArea>
    </StyledPaper>
    <div style={{ minHeight: '6.5rem' }} />
  </ContentWrapper>
);

interface IOwnProps {
  children: React.JSX.Element | Array<React.JSX.Element[] | React.JSX.Element | null | undefined> | string | null;
}

export default DocumentWrapper;
