import * as React from 'react';
import TableContent from '../../Form/SurveyForms/components/TableContent';
import { ISupplyShortagesInquiry, isLocaleKey, supplyShortagesInquiry } from 'neuro-schemas';
import { MyServiceContext } from '../..';
import { formatPartialDate } from 'neuro-utils';
import ActionButtonRounded from 'Components/ActionButtonRounded';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { TSurveyType } from 'Routes/MyService/util';
import colors from '../../../../../config/theme/colors';
import { styled } from '@mui/system';
import { assertCapabilities } from 'Store/index';
import PlatformCapabilities from '../../../../../config/capabilities';
import { ICapabilityContextProps, withCapabilities } from 'Containers/CapabilityHandler';
import { actions } from 'Store/myapp/actions';
import { useDispatch } from 'react-redux';
import ConfirmationDialog from 'Components/ConfirmationDialog';
import { ICompactVaultUser } from 'neuro-data-structures';

const InlineDiv = styled('div')({
  display: 'inline-flex',
});

const TrashIcon = (): React.JSX.Element => (
  <svg width="18" height="18" viewBox="-4 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.33317 5.5V13.8333H2.6665V5.5H9.33317ZM8.08317 0.5H3.9165L3.08317 1.33333H0.166504V3H11.8332V1.33333H8.9165L8.08317 0.5ZM10.9998 3.83333H0.999837V13.8333C0.999837 14.75 1.74984 15.5 2.6665 15.5H9.33317C10.2498 15.5 10.9998 14.75 10.9998 13.8333V3.83333Z"
      fill="#6A6A6A"
    />
  </svg>
);

const StyledMenuIcon = ({ ...other }) => (
  <IconButton
    disableRipple
    disableFocusRipple
    {...other}
    sx={{ padding: '0 1rem', color: '#6c96ae', cursor: 'pointer' }}
  >
    <MoreVertIcon />
  </IconButton>
);

const SuppliesNeededFormatted = ({
  suppliesNeeded,
  fm,
}: {
  suppliesNeeded: ISupplyShortagesInquiry['suppliesNeeded'];
  fm: (id: string) => string;
}): React.JSX.Element => {
  return (
    <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
      {suppliesNeeded?.map((s, i, arr) => {
        // Leave "specifier" out
        const name = fm(`opts.${s}`)?.includes(' (') ? fm(`opts.${s}`).split(' (')[0] : fm(`opts.${s}`);
        return <span key={i}>{`${name}${i < arr.length - 1 ? ', ' : ''}`}</span>;
      })}
    </div>
  );
};

const StatusAndResolutionDateFormatted = ({
  status,
  resolutionDate,
  resolverId,
  fm,
}: {
  status: ISupplyShortagesInquiry['status'];
  resolutionDate: ISupplyShortagesInquiry['resolutionDate'];
  resolverId: ISupplyShortagesInquiry['resolverId'];
  fm: (id: string) => string;
}): React.JSX.Element => {
  const noActionRequired = status === 'completed' && !resolverId;
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', minWidth: '4rem' }}>
        {/* Pending status is unused (in this element)*/}
        {
          {
            pending: <></>,
            completed: (
              <CheckCircleIcon
                style={{ marginRight: '0.5rem', color: noActionRequired ? colors.mediumGray : colors.success }}
              />
            ),
            rejected: <TrashIcon />,
          }[status ?? 'pending']
        }
      </div>
      {noActionRequired ? (
        <div style={{ color: colors.tertiaryText }}>{fm('myService.noActionRequired')}</div>
      ) : (
        <div>
          <InlineDiv style={{ color: colors.tertiaryText, width: 'max-content', paddingRight: '1rem' }}>{`${fm(
            `myService.${status}`,
          )}`}</InlineDiv>
          <InlineDiv style={{ color: colors.tertiaryText, whiteSpace: 'nowrap' }}>{`${formatPartialDate(
            resolutionDate,
          )}`}</InlineDiv>
        </div>
      )}
    </div>
  );
};

/**
 * For resolved supplies requests
 */
const SuppliesHistoryTable = ({
  supplyShortagesInquiryDocs,
  users,
  capabilityGroups = {},
}: ISuppliesHistoryTable): React.JSX.Element => {
  const supplyShortagesInquiryLocales = supplyShortagesInquiry.localizations;
  const myServiceContext = React.useContext(MyServiceContext);
  const { fm, locale, setViewingObj } = myServiceContext;

  const deletingCapability = assertCapabilities([PlatformCapabilities.MYSERVICE_SURVEYS_DELETION], capabilityGroups);
  const [deleteId, setDeleteId] = React.useState<string | null>(null);

  const [menuData, setMenuData] = React.useState<{
    anchor: null | HTMLElement;
    data: Record<string, any>;
    type: string;
  } | null>(null);

  const toggleMenu =
    (data: Record<string, any>, type: string) =>
    (e: React.MouseEvent<HTMLElement>): void => {
      setMenuData(menuData ? null : { anchor: e.currentTarget, data, type });
    };

  const useLocale = isLocaleKey(locale) ? locale : 'fi';

  const fmSupplyShortagesInquiry = (id: string | number) => {
    return supplyShortagesInquiryLocales[useLocale][id];
  };

  const dispatch = useDispatch();

  const handleStatusChangeToUnresolved = async (id: string) => {
    await actions.putNewCommit(
      'supplyShortagesInquiry',
      id,
      {
        ...supplyShortagesInquiryDocs.find((d) => d._id === id),
        status: 'pending',
        resolutionDate: null,
        resolverId: null,
      },
      dispatch,
    );
  };

  const surveyResolver = (document: ISupplyShortagesInquiry, users: ICompactVaultUser[]): string => {
    const hcpResolver = users.find((u) => u.userId === document.resolverId);
    if (hcpResolver) return `${hcpResolver.firstNames} ${hcpResolver.lastNames}`;

    return '';
  };

  return (
    <React.Fragment>
      <TableContent
        width={100}
        titleRow={{
          title: {
            content: fm('myService.orderDate'),
            xs: 1,
            style: { fontWeight: 400, whiteSpace: 'nowrap', width: '10rem' },
          },
          content: ['supplies', 'status', 'resolver', ''].map((title, index, array) => ({
            content: index < array.length - 1 ? fm(`myService.${title}`) : '',
            xs:
              {
                supplies: 5,
                status: 2,
                resolver: 1,
              }[title] ?? 2,
            style: {
              fontWeight: 400,
            },
          })),
        }}
        isHistory
      >
        {
          supplyShortagesInquiryDocs.map((doc, index) => {
            return {
              title: {
                content: formatPartialDate(doc.date),
                xs: 1,
                style: {
                  whiteSpace: 'nowrap',
                  width: '10rem',
                },
              },
              content: [
                {
                  content: (
                    <SuppliesNeededFormatted suppliesNeeded={doc.suppliesNeeded} fm={fmSupplyShortagesInquiry} />
                  ),
                  xs: 5,
                },
                {
                  content: (
                    <StatusAndResolutionDateFormatted
                      status={doc.status}
                      resolutionDate={doc.resolutionDate}
                      resolverId={doc.resolverId}
                      fm={fm}
                    />
                  ),
                  xs: 2,
                },
                {
                  content: <span>{surveyResolver(doc, users)}</span>,
                  xs: 1,
                },
                {
                  content: (
                    <div key={`${index}`} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      <ActionButtonRounded
                        text="myService.show"
                        filled={true}
                        width={6.6}
                        height={2.5}
                        fontSize={16}
                        uppercase={false}
                        onClick={() => {
                          doc._type && setViewingObj({ type: doc._type, data: doc as TSurveyType });
                        }}
                      />

                      <StyledMenuIcon onClick={toggleMenu(doc, doc._type)} />
                    </div>
                  ),
                  style: {
                    overflow: 'visible',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  },
                  xs: 2,
                },
              ],
              style: {
                alignItems: 'center',
                color: colors.tertiaryText,
              },
            };
          }) as []
        }
      </TableContent>

      <Menu id="surveyhistorymenu" anchorEl={menuData?.anchor} open={Boolean(menuData)} onClose={toggleMenu({}, '')}>
        {menuData?.data.resolverId && (
          <MenuItem
            onClick={(e) => {
              handleStatusChangeToUnresolved(menuData?.data._id);
              toggleMenu({}, '')(e);
            }}
          >
            {fm('myService.setAsUnresolved')}
          </MenuItem>
        )}
        {/* <MenuItem
          onClick={() => menuData?.type && setEditingObj({ type: menuData?.type, data: menuData?.data as TSurveyType })}
        >
          {fm('general.edit')}
        </MenuItem> */}
        {deletingCapability && (
          <MenuItem
            onClick={(e) => {
              setDeleteId(menuData?.data._id);
              toggleMenu({}, '')(e);
            }}
          >
            {fm('general.delete')}
          </MenuItem>
        )}
      </Menu>
      <ConfirmationDialog
        open={!!deleteId}
        text={fm('general.reallyWantToDelete')}
        cancel={{ callback: () => setDeleteId(null) }}
        confirm={{
          callback: () => {
            if (deleteId) {
              actions.deleteDocument('supplyShortagesInquiry', deleteId, dispatch);
              setDeleteId(null);
            }
          },
        }}
      />
    </React.Fragment>
  );
};

interface ISuppliesHistoryTable extends ICapabilityContextProps {
  supplyShortagesInquiryDocs: (ISupplyShortagesInquiry & IControlProps)[];
  users: ICompactVaultUser[];
}

export default withCapabilities(SuppliesHistoryTable);
